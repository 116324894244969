import { SalesModal, SingleValueCell, TableList } from "@common-components";
import styles from "./IngestionAccountsTable.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/configureStore";
import {
  IClassificationType,
  IIngestionAccountsTableData,
  ITableHeader
} from "interfaces/ingestionAccounts.interface";
import { Button, Cell, Row, useModal } from "@clayui/core";
import { PaginationBar } from "../../common-ui-modules/common-components/PaginationBar/PaginationBar";
import { IIngestionDomainsTable } from "interfaces/domains.interface";
import {
  defaultSort,
  predefinedValues
} from "@pages/filterIngestion/Tabs/IngestionAccounts/IngestionAccountsConstants";
import { staticIds } from "@staticIds";
import { staticStrings } from "@staticStrings";
import {
  setAccountLinkedInUsername,
  setIngestionAccountsCardContent,
  setOrderBy,
  setSelectedAccountId,
  setSelectedPage,
  setSortByColumn,
  setTableRowIsActive
} from "@store/ingestionAccounts/ingestionAccounts.slice";
import { useState } from "react";
import { Sorting } from "@clayui/core/lib/table/context";
import moment from "moment";
import DropDown from "@clayui/drop-down/lib/DropDown";
import ActionsThreeDot from "@images/ActionsThreeDots.svg";
import { PATHS, ROUTE_PATHS } from "@constants";
import { axiosHttp } from "@common-services";
import { useNavigate } from "react-router-dom";
import { getInitialAccountCardContent } from "../../utils/HelperFunctions";
import { ReactComponent as ActivateTitleIcon } from "@images/userManagement/GreenPlayButton.svg";
import { ReactComponent as InactivateTitleIcon } from "@images/userManagement/UserManagementDeletePopUp.svg";

interface IngestionAccountsTableProps {
  tableHeader: ITableHeader[];
  isLoading?: boolean;
  fetchIngestionAccounts: Function;
  fetchAccountCardContent: Function;
  linkedInModalOnOpenChange: (open: boolean) => void;
  setAlertMessage: (message: string) => void;
  setAlertOpen: (open: boolean) => void;
}

const IngestionAccountsTable: React.FC<IngestionAccountsTableProps> = ({
  tableHeader,
  isLoading,
  fetchIngestionAccounts,
  fetchAccountCardContent,
  linkedInModalOnOpenChange,
  setAlertMessage,
  setAlertOpen
}) => {
  const [sort, setSort] = useState<Sorting | null>(defaultSort);

  const [selectedAccountRow, setSelectedAccountRow] =
    useState<IIngestionAccountsTableData | null>();
  const { observer, onOpenChange, open } = useModal();
  const { tableResponse } = useSelector(
    (state: RootState) => state.ingestionAccounts
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSortChange = (sort: Sorting | null) => {
    if (sort) {
      dispatch(setOrderBy(sort.direction));
      dispatch(setSortByColumn(sort.column));
    }
    setSort(sort);
  };

  const renderPaginationBar = (items: IIngestionDomainsTable) => {
    return (
      <PaginationBar
        pageSize={predefinedValues.pageSize}
        setSelectedPage={setSelectedPage}
        currentPageNumber={tableResponse?.response.page_no}
        dataExistFlag={items?.data && items?.data.length > 0}
        totalItems={items?.total}
        paginationBarid="pagination_bar_ingestion_accounts"
      />
    );
  };

  const getClassificationBadgeColor = (classification?: string) => {
    const { classificationBadgeColor } = staticStrings.ingestion.accounts;
    return classificationBadgeColor[
      classification as keyof IClassificationType
    ];
  };

  const handleAccountStatus = (accountId: string, isActive: boolean) => {
    const apiUrl = PATHS.INGESTION.ACCOUNTS_V1;
    axiosHttp
      .patch(apiUrl, {
        account_id: accountId,
        is_active: isActive
      })
      .then((response) => {
        setAlertMessage(response?.data?.response);
        setAlertOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        const timer = setTimeout(() => setAlertOpen(false), 3000);
        dispatch(
          setIngestionAccountsCardContent(getInitialAccountCardContent())
        );
        fetchAccountCardContent();
        fetchIngestionAccounts();
        return () => clearTimeout(timer);
      });
  };

  const getStatusModalHeaderIcon = () => {
    if (selectedAccountRow?.is_active) {
      return <InactivateTitleIcon className={styles.statusModalHeaderIcon} />;
    } else {
      return <ActivateTitleIcon className={styles.statusModalHeaderIcon} />;
    }
  };

  const getStatusModalHeaderTitle = () =>
    `${
      selectedAccountRow?.is_active
        ? staticStrings.ingestion.accounts.actions.inactivate
        : staticStrings.ingestion.accounts.actions.activate
    } Account?`;

  const getStatusModalDescription = () =>
    `${selectedAccountRow?.name} ${
      selectedAccountRow?.is_active
        ? staticStrings.ingestion.accounts.modal.description.inactivate
        : staticStrings.ingestion.accounts.modal.description.activate
    }`;

  return (
    <div
      className={styles.tableContainer}
      id={staticIds.ingestion.accounts.table}
    >
      <TableList
        header={tableHeader}
        isTableLoading={isLoading}
        onSortChange={onSortChange}
        sort={sort}
      >
        {tableResponse?.response?.data?.map(
          (row: IIngestionAccountsTableData) => {
            return (
              <Row key={row.id} className={styles.tableContainerTableBodyRow}>
                <SingleValueCell cellValue={`${row.name} - ${row.party_id} `} />

                <SingleValueCell cellValue={row.no_of_domains} />
                <SingleValueCell cellValue={row.no_of_emails} />
                <SingleValueCell cellValue={row.total_active_members} />
                <Cell>
                  <div
                    className={styles.accountBadge}
                    style={
                      getClassificationBadgeColor(
                        row.classification?.toLowerCase()
                      ) ??
                      staticStrings.ingestion.accounts.classificationBadgeColor
                        .commercial
                    }
                  >
                    {row.classification ?? staticStrings.noValue}
                  </div>
                </Cell>

                <SingleValueCell
                  cellValue={
                    row.updated_at &&
                    moment(row.updated_at).format(
                      staticStrings.dateFormats.DEFAULT_UI_DATE_TIME_FORMAT
                    )
                  }
                />
                <SingleValueCell cellValue={row.comments} />
                <Cell>
                  <div
                    className={
                      styles.accountBadge +
                      " " +
                      (row.is_active
                        ? styles.accountBadgeActive
                        : styles.accountBadgeInactive)
                    }
                  >
                    {
                      staticStrings.ingestion.accounts.cards[
                        row.is_active ? 1 : 2
                      ]
                    }
                  </div>
                </Cell>
                <Cell>
                  <div>
                    <DropDown
                      trigger={
                        <Button
                          className={styles.dropDownBtn}
                          displayType="unstyled"
                          id={staticIds.ingestion.domains.actionButton}
                        >
                          <div className={styles.dropDownText}>
                            {" "}
                            <img src={ActionsThreeDot} alt="edit" />
                          </div>
                        </Button>
                      }
                      closeOnClick={true}
                    >
                      <DropDown.ItemList className={styles.dropDownItem}>
                        <DropDown.Item
                          onClick={() => {
                            setSelectedAccountRow(row);
                            onOpenChange(true);
                          }}
                        >
                          {row.is_active
                            ? staticStrings.ingestion.accounts.actions
                                .inactivate
                            : staticStrings.ingestion.accounts.actions.activate}
                        </DropDown.Item>
                        <DropDown.Item
                          onClick={() => {
                            sessionStorage.setItem(
                              "accountsTab",
                              staticStrings.ingestion.domains.active
                            );
                            navigate(
                              `${ROUTE_PATHS.DOMAIN_DETAILS_INGESTION_DOMAINS}?party-id=${row.party_id}`
                            );
                          }}
                        >
                          Add Domain
                        </DropDown.Item>
                        <DropDown.Item
                          onClick={() => {
                            dispatch(setTableRowIsActive(row.is_active));
                            dispatch(
                              setAccountLinkedInUsername(row.linkedin_user_name)
                            );
                            dispatch(setSelectedAccountId(row.id));
                            linkedInModalOnOpenChange(true);
                          }}
                        >
                          {
                            staticStrings.ingestion.accounts.actions
                              .updateLinkedIn
                          }
                        </DropDown.Item>
                      </DropDown.ItemList>
                    </DropDown>
                  </div>
                </Cell>
              </Row>
            );
          }
        )}
      </TableList>
      {tableResponse.response &&
        !isLoading &&
        renderPaginationBar(tableResponse.response)}

      {selectedAccountRow && (
        <SalesModal
          modalId={staticIds.ingestion.accounts.modal}
          open={open}
          observer={observer}
          titleIcon={getStatusModalHeaderIcon()}
          title={getStatusModalHeaderTitle()}
          onSubmit={() => {
            handleAccountStatus(
              selectedAccountRow.id,
              !selectedAccountRow.is_active
            );
            onOpenChange(false);
          }}
          onCancel={() => {
            onOpenChange(false);
          }}
          onClose={() => {
            onOpenChange(false);
          }}
          submitText={staticStrings.ingestion.accounts.modal.actions.confirm}
          cancelText={staticStrings.ingestion.accounts.modal.actions.cancel}
        >
          <div className={styles.accountStatusModalContent}>
            {getStatusModalDescription()}
          </div>
        </SalesModal>
      )}
    </div>
  );
};

export default IngestionAccountsTable;
