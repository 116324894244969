import Button from "@clayui/button";
import DropDown from "@clayui/drop-down/lib/DropDown";
import ClayIcon from "@clayui/icon";
import spritemap from "@images/icons.svg";
import { useState } from "react";
import styles from "./DropdownSelectBox.module.scss";
import { staticStrings } from "@staticStrings";
import { ContainerLoader } from "../container-loader/ContainerLoader";

interface DropdownSelectBoxProps {
  selectedOption: string;
  options: string[];
  setSelectedOption: (option: string) => void;
  dropdownId: string;
  placeHolder?: string;
  disableFlag?: boolean;
  loader?: boolean;
  searchOption?: boolean;
  dropDownLabel?: string;
}

const DropdownSelectBox: React.FC<DropdownSelectBoxProps> = ({
  selectedOption,
  options,
  setSelectedOption,
  dropdownId,
  placeHolder = staticStrings.commonTexts.select,
  disableFlag = false,
  loader = false,
  searchOption = false,
  dropDownLabel = ""
}) => {
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const filteredOptions = options?.filter((item) =>
    item.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <DropDown
      active={open}
      className={styles.dropDown}
      filterKey=""
      onActiveChange={(value: boolean) => {
        setOpen(value);
        setSearchInput("");
      }}
      trigger={
        <Button
          className={styles.dropDownBtn}
          displayType="unstyled"
          onClick={() => setOpen(!open)}
          id={dropdownId}
          disabled={disableFlag}
        >
          <div className={styles.dropDownText}>
            {selectedOption !== ""
              ? dropDownLabel || selectedOption
              : placeHolder}
          </div>
          {!open ? (
            <ClayIcon
              symbol="angle-down"
              spritemap={spritemap}
              className={styles.arrowDown}
            />
          ) : (
            <ClayIcon
              symbol="angle-up"
              spritemap={spritemap}
              className={styles.arrowUp}
            />
          )}
        </Button>
      }
    >
      {searchOption ? (
        <DropDown.Search
          placeholder={staticStrings.account360.engagedPeople.search}
          className={`${styles.searchBox}`}
          id={`search_box_${dropdownId}`}
          value={searchInput}
          onChange={(val: string) => setSearchInput(val)}
        />
      ) : (
        <></>
      )}
      <DropDown.ItemList
        items={filteredOptions}
        className={styles.dropDownItem}
      >
        {!loader ? (
          filteredOptions?.map((item, index) => {
            return (
              <DropDown.Item
                id={"dropdown_list_" + dropdownId + index}
                key={item}
                active={item === selectedOption}
                onClick={(e: any) => {
                  setSelectedOption(e.target.textContent);
                  setOpen(!open);
                }}
              >
                {item}
              </DropDown.Item>
            );
          })
        ) : (
          <div className={styles.containerLoader}>
            <ContainerLoader msgRequired={false} />
          </div>
        )}
      </DropDown.ItemList>
      {filteredOptions?.length === 0 && (
        <DropDown.Section className={`${styles.dropDownContainerWidth}`}>
          <p className={styles.textContent} style={{ padding: "0.5rem" }}>
            {staticStrings.commonTexts.noRecordsFound}
          </p>
        </DropDown.Section>
      )}
    </DropDown>
  );
};

export { DropdownSelectBox };
