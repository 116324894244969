import { Body, Cell, Head, Table } from "@clayui/core";
import styles from "./TableList.module.scss";
import React, { useCallback, useEffect, useState } from "react";
import { Sorting } from "@clayui/core/lib/table/context";
import UpDownArrow from "@images/UpDownArrow.png";
import infoIcon from "@images/info.svg";
import { ITableHeader } from "interfaces/filterIngestion.interface";
import { Tooltip } from "react-tooltip";
import { ClayCheckbox } from "@clayui/form";
import { ContainerLoader } from "../container-loader/ContainerLoader";
import { staticStrings } from "@staticStrings";
import { DropdownSelectBox } from "../DropdownSelectBox/DropdownSelectBox";
import SearchInput from "@components/SearchInput/SearchInput";
import { debounce } from "@utils";
import { useDispatch } from "react-redux";

interface TableListProps {
  header: ITableHeader[];
  isTableLoading?: boolean;
  onSortChange?: (sort: Sorting | null) => void;
  sort?: Sorting | null;
  children?: React.ReactNode;
  toggleSelectAll?: () => void;
  selectAll?: boolean;
  isCheckBox?: boolean;
  disabled?: boolean;
  filterValues?: { [key: string]: string };
  setFilterValues?: Function;
  searchKeyWord?: string;
  setSearchKeyWord?: (keyWord: string) => void;
  enableFilters?: boolean;
  setSelectedPage?: Function;
}

const TableList: React.FC<TableListProps> = ({
  onSortChange,
  sort,
  header,
  children,
  toggleSelectAll,
  selectAll = false,
  isCheckBox = false,
  isTableLoading,
  disabled = false,
  filterValues = {},
  setFilterValues = () => {},
  searchKeyWord = "",
  setSearchKeyWord = () => {},
  enableFilters = false,
  setSelectedPage = () => {}
}) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const dispatch = useDispatch();
  const handleFilterChange = (columnId: string, selectedValue: string) => {
    if (
      (filterValues?.[columnId] === undefined ||
        filterValues?.[columnId] === staticStrings.dropdownAllabel) &&
      selectedValue === staticStrings.dropdownAllabel
    )
      return;
    dispatch(setSelectedPage(1));
    setFilterValues((prevValues: object) => ({
      ...prevValues,
      [columnId]: selectedValue
    }));
  };

  useEffect(() => {
    setSearchInput(searchKeyWord);
  }, [searchKeyWord]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchKeyWord = useCallback(
    debounce(setSearchKeyWord, 500),
    [setSearchKeyWord]
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
    debouncedSetSearchKeyWord(value);
  };

  return (
    <>
      <Table
        onSortChange={onSortChange}
        borderless
        striped={false}
        sort={sort}
        className={styles.tableContainerTable}
      >
        {(!isTableLoading || enableFilters) && (
          <Head className={styles.tableContainerTableHead}>
            {header?.map((column: any, index) => {
              if (
                column.id === staticStrings.commonTexts.checkboxLabel &&
                !isCheckBox
              )
                return false;
              return (
                <Cell
                  key={column.id}
                  sortable={column?.sortable}
                  className={
                    styles.textTruncateContainer +
                    " " +
                    styles.tableContainerTableHeadCell +
                    " " +
                    (styles[column.customClass] ?? column.customClass) +
                    " " +
                    (!column?.sortable
                      ? styles.tableContainerTableHeadEmptyCell
                      : "")
                  }
                  textValue={column.id}
                  id={`table_column_${column.id}`}
                  style={{ pointerEvents: "none" }}
                >
                  <div
                    className={`
                      ${styles.tableContainerTableHeadCellTextIconContainer} ${styles[column.customHeaderContainerClass]} ${column?.sortable && styles.sortableColumn}`}
                  >
                    {column.id === staticStrings.commonTexts.checkboxLabel &&
                      isCheckBox &&
                      !isTableLoading && (
                        <ClayCheckbox
                          className="mr-2"
                          checked={selectAll}
                          onChange={toggleSelectAll}
                          disabled={disabled}
                          style={{ pointerEvents: "all" }}
                        />
                      )}
                    {column.id !== "checkbox" && (
                      <span className="mr-2">{column.name}</span>
                    )}

                    {column.tooltip && (
                      <>
                        <img
                          src={infoIcon}
                          alt="info"
                          data-tooltip-id={"tooltip" + index}
                          className={styles.tooltipTrigger}
                        />
                        <Tooltip
                          id={"tooltip" + index}
                          place="right"
                          positionStrategy="fixed"
                          offset={20}
                          className={`${styles.headerTooltip} increase-z-index`}
                          html={`${column.tooltip}`}
                        ></Tooltip>
                      </>
                    )}

                    {column?.sortable && <img src={UpDownArrow} alt="" />}
                  </div>
                  {enableFilters && column?.searchPlaceHolder && (
                    <div
                      className={styles.searchUserContainer}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                    >
                      <SearchInput
                        onChange={handleSearch}
                        searchValue={searchInput}
                        placeholder={column.searchPlaceHolder}
                      />
                    </div>
                  )}
                  {enableFilters && column?.filterOptions?.length && (
                    <div
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                      className={styles.selectDropdown}
                    >
                      <DropdownSelectBox
                        selectedOption={filterValues[column.id] || ""}
                        options={column.filterOptions}
                        setSelectedOption={(value) =>
                          handleFilterChange(column.id, value)
                        }
                        dropdownId="account_division_dropdown"
                        placeHolder={"All"}
                        searchOption={true}
                      />
                    </div>
                  )}
                </Cell>
              );
            })}
          </Head>
        )}
        <Body className={styles.tableContainerTableBody}>
          {!isTableLoading && children}
        </Body>
      </Table>
      {isTableLoading && <ContainerLoader />}
    </>
  );
};

export { TableList };
