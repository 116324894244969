import { createSlice } from "@reduxjs/toolkit";

interface AccountReportInterface {
  userPermissions?: string[];
  userContacts: string[];
}

const initialState: AccountReportInterface = {
  userPermissions: [],
  userContacts: []
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    },
    setUserContacts: (state, action) => {
      state.userContacts = action.payload;
    }
  }
});

export const { setUserPermissions, setUserContacts } = configSlice.actions;
export default configSlice.reducer;
