enum ORDER_BY {
  ASC = "ascending",
  DESC = "descending"
}

export enum PERMISSIONS {
  ADMIN = "Admin",
  MANAGER = "Manager",
  SALES_REPRESENTATIVE = "Sales Representative"
}

//Account 360

export const DEAFULT_SORT_UPCOMING_MEETINGS = "start_date_time";
export const DEFAULT_ORDER_BY_UPCOMING_MEETINGS = ORDER_BY.ASC;

export const DEFAULT_SORT_BY_ENGAGED_PEOPLE: string =
  "total_activity_in_minutes";

export const DEFAULT_ORDER_BY_ENGAGED_PEOPLE = ORDER_BY.DESC;

export const DEFAULT_ORDER_BY_ACCOUNT_TEAM: string = ORDER_BY.DESC;

export const DEFAULT_SORT_BY_ACCOUNT_TEAM: string = "contacts_engaged";

export const DEFAULT_SORT_BY_ACCOUNT360_DOMAINS: string = "total_emails";
export const DEFAULT_ORDER_BY_ACCOUNT360_DOMAINS = ORDER_BY.DESC;

// Ingestion

export const DEFAULT_SORT_BY_FILTER_INGESTION: string = "last_updated_at";
export const DEFAULT_ORDER_BY_FILTER_INGESTION: string = ORDER_BY.DESC;

export const DEFAULT_SORT_BY_DOMAIN_INGESTION: string = "account_name";
export const DEFAULT_ORDER_BY_DOMAIN_INGESTION = ORDER_BY.ASC;

export const DEFAULT_SORT_BY_DOMAIN_INGESTION_DOMAIN_DETAILS: string =
  "total_emails";
export const DEFAULT_ORDER_BY_DOMAIN_INGESTION_DOMAIN_DETAILS = ORDER_BY.DESC;

export const DEFAULT_SORT_BY_INGESTION_ACCOUNTS: string = "comments";
export const DEFAULT_ORDER_BY_INGESTION_ACCOUNTS = ORDER_BY.ASC;

export const DEFAULT_SORT_BY_ELOQUA: string = "rule_name";
export const DEFAULT_ORDER_BY_ELOQUA = ORDER_BY.ASC;

export const DEFAULT_SORT_BY_ELOQUASIGNATURE: string = "name";
export const DEFAULT_ORDER_BY_ELOQUASIGNATURE = ORDER_BY.ASC;
// Reporting

export const DEFAULT_SORT_BY_ACCOUNT: string = "name";
export const DEFAULT_ORDER_BY_ACCOUNT = ORDER_BY.ASC;

export const DEFAULT_SORT_BY_CUSTOM_TEAM_REPORTING: string = "name"; // change to correct value provided by BE
export const DEFAULT_ORDER_BY_CUSTOM_TEAM_REPORTING = ORDER_BY.ASC;

export const DEFAULT_SORT_BY_CUSTOM_TEAM_REPORTING_DETAILS = "contacts_engaged";
export const DEFAULT_ORDER_BY_CUSTOM_TEAM_REPORTING_DETAILS = ORDER_BY.DESC;

export const DEFAULT_SORT_BY_MY_ACTIVITIES: string = "name";
export const DEFAULT_ORDER_BY_MY_ACTIVITIES = ORDER_BY.ASC;

//User Management
export const DEFAULT_SORT_BY_USER_MANAGEMENT: string = "last_login";
export const DEFAULT_ORDER_BY_USER_MANAGEMENT = ORDER_BY.DESC;

export const DEFAULT_SORT_BY_PEOPLE_REPORTING: string = "contacts_engaged";
export const DEFAULT_ORDER_BY_PEOPLE_REPORTING: string = ORDER_BY.DESC;

export const DEFAULT_SORT_BY_CUSTOM_TEAMS: string = "name";
export const DEFAULT_ORDER_BY_CUSTOM_TEAMS = ORDER_BY.ASC;

export const DEFAULT_SORT_BY_CUSTOM_TEAM_DETAILS: string = "email_address";
export const DEFAULT_ORDER_BY_CUSTOM_TEAM_DETAILS = ORDER_BY.ASC;

export const DEFAULT_SORT_BY_SHOW_USERS_CREATE_CUSTOM_TEAM: string =
  "email_address";
export const DEFAULT_ORDER_BY_SHOW_USERS_CREATE_CUSTOM_TEAM = ORDER_BY.ASC;

export const DEFAULT_SORT_BY_ACCOUNT_INGESTION: string = "name";
export const DEFAULT_ORDER_BY_ACCOUNT_INGESTION = ORDER_BY.ASC;

export const DEFAULT_API_INIT_STATE = {
  response: {
    error: null,
    page_no: 0,
    total: 0,
    data: []
  }
};
export const COMMON_SORT: string = "email_address";
export const COMMON_SORT_ENGAGED_PEOPLE: string = "last_engaged";
export const COMMON_ORDER_BY_ACCOUNT_TEAM: string = ORDER_BY.ASC;

// engaged people -> graph view
export const DEFAULT_SELECTED_TOP_VIEW = "Top 10";
export const DEFAULT_SELECTED_DIVISION = "Total Activity";
