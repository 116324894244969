import React, { useEffect } from "react";
import ClayTabs from "@clayui/tabs";
import styles from "./Tabs.module.scss";
import { staticStrings } from "../../../utils/constants/staticStrings";
import ClayLayout from "@clayui/layout";
import FilterIngestion from "./FilterIngestion/FilterIngestion";
import Domains from "./Domains/Domains";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTab,
  setInitDomainIngestion
} from "@store/filterIngestion/domains/domains.slice";
import { setInitFilterIngestion } from "@store/filterIngestion/filterIngestion.slice";
import { RootState } from "@store/configureStore";
import { user_permissions } from "../../../utils/constants/permissions";
import IngestionAccounts from "./IngestionAccounts/IngestionAccounts";
import { setInitIngestionAccounts } from "@store/ingestionAccounts/ingestionAccounts.slice";

const Tabs: React.FC = () => {
  const { userPermissions } = useSelector((state: RootState) => state.config);
  const { activeTab } = useSelector((state: RootState) => state.domains);
  const permissions = [
    user_permissions.customFilters.VIEW_CUSTOM_FILTERS,
    user_permissions.ingestion.EDIT_DOMAINS,
    user_permissions.ingestion.VIEW_ACCOUNTS
  ];

  const { tabs } = staticStrings.ingestion;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setInitDomainIngestion());
    dispatch(setInitFilterIngestion());
    dispatch(setInitIngestionAccounts());
  }, [activeTab, dispatch]);

  useEffect(() => {
    if (
      !userPermissions.includes(
        user_permissions.customFilters.VIEW_CUSTOM_FILTERS
      ) &&
      !userPermissions.includes(user_permissions.ingestion.EDIT_DOMAINS) // only admin should be able to edit/delete domains
    ) {
      dispatch(setActiveTab(-1));
    } else if (
      !userPermissions.includes(
        user_permissions.customFilters.VIEW_CUSTOM_FILTERS
      )
    ) {
      dispatch(setActiveTab(1));
    } else if (
      !userPermissions.includes(user_permissions.ingestion.EDIT_DOMAINS) // only admin should be able to edit/delete domains
    ) {
      dispatch(setActiveTab(0));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setActiveTab(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ClayLayout.Row className="ml-0">
        <ClayTabs active={activeTab} className={styles.clayTab}>
          {tabs.map((tab, i) => (
            <ClayTabs.Item
              key={tab}
              innerProps={{
                "aria-controls": `tabpanel-${i + 1}`
              }}
              onClick={() => {
                dispatch(setActiveTab(i));
              }}
              hidden={!userPermissions.includes(permissions[i])}
              id={`page_tab_${i}`}
            >
              {tab}
            </ClayTabs.Item>
          ))}
        </ClayTabs>
      </ClayLayout.Row>

      <ClayTabs.Content
        active={activeTab}
        fade
        className={styles.clayTabContent}
      >
        <ClayTabs.TabPane
          aria-labelledby="tab-1"
          className={styles.clayTabContentTab1}
        >
          {activeTab === 0 && <FilterIngestion />}
        </ClayTabs.TabPane>
        <ClayTabs.TabPane
          aria-labelledby="tab-1"
          className={styles.clayTabContentTab1}
        >
          {activeTab === 1 && <Domains />}
        </ClayTabs.TabPane>
        <ClayTabs.TabPane
          aria-labelledby="tab-1"
          className={styles.clayTabContentTab1}
        >
          {activeTab === 2 && <IngestionAccounts />}
        </ClayTabs.TabPane>
      </ClayTabs.Content>
    </>
  );
};

export default Tabs;
