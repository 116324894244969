import {
  DEFAULT_ORDER_BY_DOMAIN_INGESTION_DOMAIN_DETAILS,
  DEFAULT_SORT_BY_DOMAIN_INGESTION_DOMAIN_DETAILS
} from "../../../utils/constants/reducerConstants";
import { createSlice } from "@reduxjs/toolkit";
import {
  IDomainsCardDetails,
  IIngestionDomainDetailsTableResponse
} from "interfaces/domains.interface";

interface DomainDetailsInterface {
  domainDetailsCardContent: IDomainsCardDetails[];
  emailCardContent: IDomainsCardDetails[];
  searchKeyWord: string;
  tableResponse: IIngestionDomainDetailsTableResponse;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  selectedDomainId: string;
  selectedAccountId: string;
  checkedDomainIds: string[];
  domainDetailsAccountTitle: string;
  selectedDeleteDomainName: string;
  isDomain: boolean;
}

const initialState: DomainDetailsInterface = {
  domainDetailsCardContent: [],
  emailCardContent: [],
  searchKeyWord: "",
  orderBy: DEFAULT_ORDER_BY_DOMAIN_INGESTION_DOMAIN_DETAILS,
  sortByColumn: DEFAULT_SORT_BY_DOMAIN_INGESTION_DOMAIN_DETAILS,
  selectedPage: 1,
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: { account_name: "", domains: [], party_id: "" }
    }
  },
  selectedDomainId: "",
  selectedAccountId: "",
  checkedDomainIds: [],
  domainDetailsAccountTitle: "",
  selectedDeleteDomainName: "",
  isDomain: true
};

const domainDetailsSlice = createSlice({
  name: "domainDetails",
  initialState,
  reducers: {
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setDomainDetailsCardContent: (state, action) => {
      state.domainDetailsCardContent = action.payload;
    },
    setEmailCardContent: (state, action) => {
      state.emailCardContent = action.payload;
    },
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setSelectedDomainId: (state, action) => {
      state.selectedDomainId = action.payload;
    },
    setSelectedAccountId: (state, action) => {
      state.selectedAccountId = action.payload;
    },
    setCheckedDomainIds: (state, action) => {
      state.checkedDomainIds = action.payload;
    },
    setDomainDetailsAccountTitle: (state, action) => {
      state.domainDetailsAccountTitle = action.payload;
    },
    setSelectedDeleteDomainName: (state, action) => {
      state.selectedDeleteDomainName = action.payload;
    },
    setIsDomain: (state, action) => {
      state.isDomain = action.payload;
    },
    setInitDomainDetails: () => {
      return initialState;
    }
  }
});

export const {
  setSearchKeyWord,
  setDomainDetailsCardContent,
  setEmailCardContent,
  setOrderBy,
  setSelectedPage,
  setSortByColumn,
  setTableResponse,
  setSelectedDomainId,
  setSelectedAccountId,
  setCheckedDomainIds,
  setDomainDetailsAccountTitle,
  setSelectedDeleteDomainName,
  setIsDomain,
  setInitDomainDetails
} = domainDetailsSlice.actions;
export default domainDetailsSlice.reducer;
