export const BASEPATH = window.env.REACT_APP_BASE_URL;

export const PATHS = {
  USER_CONFIG: {
    USER_PERMISSIONS: `${BASEPATH}/v1/users/permissions`,
    USER_GOOGLE_CONTACTS: `${BASEPATH}/v1/users/google-contacts`
  },
  ACCOUNT_360: {
    ACCOUNT_LIST: `${BASEPATH}/v1/accounts/`,
    ACCOUNT_VERIFY: `${BASEPATH}/v1/accounts/details/`,
    INCORTA_LINK: (partyId: string) =>
      window.env.REACT_APP_INCORTA_LINK.replace("<replace-party-id>", partyId),
    LINKEDIN_ACCOUNT_URL: (linkedInUsername: string) =>
      window.env.REACT_APP_LINKEDIN_ACCOUNT_LINK.replace(
        "<replace-account-id>",
        linkedInUsername
      ),
    MARKETING_ACTIVITIES_LINK: (partyId: string) =>
      window.env.REACT_APP_MARKETING_ACTIVITIES_LINK.replace(
        "<replace-party-id>",
        partyId
      ),
    LINKEDIN_USER_REJECT: `${BASEPATH}/v1/user/linkedin`,
    LINKDIN_USER_URL: window.env.REACT_APP_LINKEDIN_USER_LINK,
    SUMMARY: {
      ACTIVITY_TRENDS: (accountId: string) =>
        `${BASEPATH}/v1/accounts/${accountId}/activity-trends`,
      EMAILS_LIST: (accountId?: string) =>
        `${BASEPATH}/v1/accounts/${accountId}/emails`,
      EVENTS_LIST: (accountId?: string) =>
        `${BASEPATH}/v1/accounts/${accountId}/events`
    },
    ENGAGE_PEOPLE: {
      ACCOUNT_SUMMARY: (accountId: string) =>
        `${BASEPATH}/v1/accounts/${accountId}/engaged-user-summary`,
      LIST: (accountId: string) =>
        `${BASEPATH}/v1/accounts/${accountId}/get-users-engagement-details`,
      USER_DROPDOWN: (accountId: string) =>
        `${BASEPATH}/v1/accounts/${accountId}/engaged-users`,
      EXTERNAL_CONNECTIONS: (
        accountId: string,
        userId: string,
        startDateTime: string,
        endDateTime: string
      ) =>
        `${BASEPATH}/v1/accounts/${accountId}/connections/${userId}?startDateTime=${startDateTime}&endDateTime=${endDateTime}`,
      CONNECTIONS_VIEW: {
        LIST: (
          accountId: string,
          startDateTime: string,
          endDateTime: string,
          peopleCount: number,
          activityType: string,
          userType: string
        ) =>
          `v1/accounts/${accountId}/connections-sanky-graph?startDateTime=${startDateTime}&endDateTime=${endDateTime}&peopleCount=${peopleCount}&activityType=${activityType}&userType=${userType}`
      }
    },
    ACCOUNT_TEAM: {
      ACCOUNT_TEAM_LIST: (accountId: string) =>
        `${BASEPATH}/v1/accounts/${accountId}/account-team`,
      ACCOUNT_TEAM_EMAIL_LIST: (accountId: string) =>
        `${BASEPATH}/v1/accounts/${accountId}/all-account-team-unpaginated`
    },
    ENGAGEMENT_SCORE: (accountId: string) =>
      `${BASEPATH}/v1/accounts/${accountId}/engagement-score-summary`
  },
  REPORTING: {
    ACCOUNT: `${BASEPATH}/v2/reporting/get-accounts`,
    HIERARCHY_TREE_LIST: `${BASEPATH}/v1/accounts/hierarchy`,
    PEOPLE: {
      PEOPLE_REPORTING_LIST: `${BASEPATH}/v2/reporting/get-people`,
      FUNCTIONS_LIST: `${BASEPATH}/v1/reporting/functions`
    },
    DIVISIONS_LIST: `${BASEPATH}/v1/reporting/divisions`,
    CUSTOM_TEAMS: `${BASEPATH}/v1/reporting/custom-teams`,
    CUSTOM_TEAMS_DETAILS: (customTeamId: string | null) =>
      `${BASEPATH}/v1/reporting/custom-teams/${customTeamId}`,
    MY_ACTIVITIES: `${BASEPATH}/v1/reporting/my-activities`,
    USER_ACCOUNT_LIST: `${BASEPATH}/v1/users/accounts`
  },
  FILTER_INGESTION: {
    LIST: () => `${BASEPATH}/v1/filters`,
    FETCH_TYPES_FIELDS: () => `${BASEPATH}/v1/filter/options`
  },
  USER_MANAGEMENT: {
    LIST: `${BASEPATH}/v1/user-management`,
    USER_SUMMARY: `${BASEPATH}/v1/user-management/summary`,
    USER_GROUPS: `${BASEPATH}/v1/user-management/groups`,
    FETCH_USER: `${BASEPATH}/v1/user-management/user`,
    SHOW_USER_LIST_AND_ACCOUNTS: `${BASEPATH}/v1/get-users-and-accounts`,
    CREATE_CUSTOM_TEAM: `${BASEPATH}/v1/custom-teams`,
    LOCATION: `${BASEPATH}/v1/user-management/locations`,
    TEAM_DETAILS: (customTeamId: string | null) =>
      `${BASEPATH}/v1/custom-teams/${customTeamId}`,
    REMOVE_CUSTOM_TEAMS_USER: (customTeamId: string | null) =>
      `${BASEPATH}/v1/custom-teams/${customTeamId}`,
    ACCOUNTS: {
      LIST: (userEmail: string) =>
        `${BASEPATH}/v1/user-management/${userEmail}/accounts`
    }
  },
  INGESTION: {
    DOMAINS: {
      DOMAINS_ACCOUNTS: `${BASEPATH}/v1/domains`,
      LIST: `${BASEPATH}/v1/domains`,
      EXPORT: `${BASEPATH}/v1/domains-export`,
      DOMAIN_DETAILS: {
        LIST: (partyId: string) => `${BASEPATH}/v1/domains/${partyId}`
      }
    },
    ACCOUNTS: `${BASEPATH}/v2/ingestion/accounts`,
    ACCOUNTS_V1: `${BASEPATH}/v1/ingestion/accounts`,
    ACCOUNT_SUMMARY: `${BASEPATH}/v1/ingestion/account-summary`
  },
  INTEGRATION: {
    ELOQUA: {
      LIST: `${BASEPATH}/v1/eloqua-filter`,
      FETCH_RULES: `${BASEPATH}/v1/eloqua-filter-criteria`,
      SIGNATURE_LIST: `${BASEPATH}/v1/signature-review`,
      ELOQUA_SUMMARY: `${BASEPATH}/v1/eloqua-integration-summary`
    }
  }
};
