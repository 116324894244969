import momentTimezone from "moment-timezone";
import moment, { Moment, MomentInput } from "moment";
import { staticStrings } from "@staticStrings";
import {
  IAccount,
  IAccountLevel,
  IAttendeeData,
  IHierarchyTreeData,
  ISalesArea,
  ISalesOrganization,
  ISalesRegion,
  ISalesRepresentativeSearch,
  ITreeNode,
  IUser,
  TreeLevels
} from "@interfaces";
import { RefObject } from "react";
import { IFontSize } from "interfaces/activityTrends.interface";
import { ICreateTeamAccount } from "../interfaces/userManagement.interface";
import { DomainStatusResponse } from "interfaces/domains.interface";
import { TableHeader, HeaderSetting } from "interfaces/accounts.interface";
import Total from "@images/ingestionAccounts/total.svg";
import Active from "@images/userManagement/UserManagementActive.svg";
import Inactive from "@images/userManagement/UserManagementInactive.svg";
import { EloquaStatusResponse } from "interfaces/eloqua.interface";
import { AccountStatusResponse } from "interfaces/userAccounts.interface";
import { ITableHeader } from "interfaces/filterIngestion.interface";

const formatFromUTCDate = (
  dateStr: string,
  inputDateFormat = "YYYY/MM/DDTHH:mm:ssZ",
  outputFormat = "MMMM Do YYYY, h:mm a z"
) => {
  let formattedDate: string = "";
  if (!!dateStr) {
    const browserTimezone = momentTimezone.tz.guess();
    formattedDate = moment(moment.utc(dateStr), inputDateFormat)
      .tz(browserTimezone)
      .format(outputFormat);
  }
  return formattedDate;
};

export const convertDateToUTC = (
  dateStr: string,
  inputDateFormat = staticStrings.dateFormats.DMMMYY,
  outputFormat = staticStrings.dateFormats.YYYYMMDD_WITH_HOURS
) => {
  let formattedDate: string = "";
  if (!!dateStr) {
    formattedDate = moment(dateStr, inputDateFormat).utc().format(outputFormat);
  }
  return formattedDate;
};

export const getCurrentDateTimeinUTC = (
  outputFormat = staticStrings.dateFormats.YYYYMMDD_WITH_HOURS
) => {
  return moment().utc().format(outputFormat);
};

const isToday = (date: Moment): boolean => {
  return date.isSame(moment(), "day");
};

export const convertDateToCommonFormat = (date: MomentInput) =>
  moment(date, staticStrings.dateFormats.DMMMYY).format(
    staticStrings.dateFormats.YYYYMMDD_WITH_HOURS
  );

const minutesToHours = (totalMinutes: number): string => {
  if (totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const displayMinutes = minutes === 1 ? `${minutes}min` : `${minutes}mins`;

    if (minutes !== 0) {
      if (hours !== 0) return `${hours}h ${displayMinutes}`;
      return `${displayMinutes}`;
    }
    return `${hours}h`;
  }
  return `0min`;
};

export const getUserName = (name?: string, email?: string): string => {
  if (name) {
    return name;
  } else if (email) {
    return email;
  }
  return "---";
};
export const getUserDropDownTotalCheckBoxCount = (
  salesRepresentative: ISalesRepresentativeSearch[]
) => {
  const totalCount = salesRepresentative.reduce(
    (count, item) => (item.is_checked ? count + 1 : count),
    0
  );
  return totalCount;
};

export const capitalizeFirstLetter = (text: string) => {
  const UpperCaseWord = text
    ?.replace(/_/g, " ")
    .toLowerCase()
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });

  if (UpperCaseWord.includes("/")) {
    const words = UpperCaseWord.split("/");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].replace(/^./, function (str) {
        return str.toUpperCase();
      });
    }
    return words.join("/");
  }

  return UpperCaseWord;
};

export const getPercentage = (part: number, total: number) => {
  return (part / total) * 100;
};

export const getEndOfDay = (
  date: string,
  inputDateFormat = staticStrings.dateFormats.UTC,
  outputFormat = staticStrings.dateFormats.YYYYMMDD_WITH_HOURS
) =>
  moment(moment.utc(date).endOf("day"), inputDateFormat).format(outputFormat);

export const formatPopUpObjectMeetings = (data: IAttendeeData[]) => {
  return data.map((item) => ({
    name: item.attendee.name ?? "",
    title: item.attendee.title ?? "",
    email_address: item.attendee.email_address ?? "",
    account: item.attendee.account ?? "",
    profile_picture: item.attendee.profile_picture,
    is_active: item.attendee.is_active ?? false
  }));
};

export const formatPopUpObjectEmails = (data: IUser[]) => {
  return data?.map((item) => ({
    name: item.name ?? "",
    title: item.title ?? "",
    email_address: item.email_address ?? "",
    account: item.account ?? "",
    profile_picture: item.profile_picture,
    is_active: item.is_active ?? false
  }));
};

interface NumDictionary {
  [key: number]: number;
}
const maxCharDisplayDict: NumDictionary = {
  1450: 40,
  1600: 60,
  1800: 80,
  2400: 100,
  3000: 120
};
export const handleResize = (containerWidth: number) => {
  for (const breakpoint of Object.keys(maxCharDisplayDict)) {
    if (containerWidth < parseInt(breakpoint)) {
      return maxCharDisplayDict[parseInt(breakpoint)];
    }
  }
  // Fallback for larger container widths
  return 125;
};
/**
 * Converts a flat data structure representing sales regions, areas, organizations,
 * and accounts into a hierarchical tree structure.
 *
 * @param data - The input data array containing sales regions, areas, organizations, and accounts.
 * @returns An array representing the hierarchical tree structure.
 */
export const convertDataToTree = (
  data: ISalesRegion[],
  hideCheckbox: string[] = []
) => {
  return data.map(
    ({ id: regionId, name: regionName, sales_area }: ISalesRegion) => {
      /**
       * Map sales areas to a structure containing sales organizations and accounts.
       *
       * @param area - The sales area object.
       * @returns An object representing a sales area in the tree structure.
       */
      const salesArea = sales_area.map(
        ({ id: areaId, name: areaName, sales_organizations }: ISalesArea) => {
          /**
           * Map sales organizations to a structure containing accounts.
           *
           * @param org - The sales organization object.
           * @returns An object representing a sales organization in the tree structure.
           */
          const salesOrgs = sales_organizations.map(
            ({ id: orgId, name: orgName, accounts }: ISalesOrganization) => {
              /**
               * Map accounts to a structure containing account details.
               *
               * @param account - The account object.
               * @returns An object representing an account in the tree structure.
               */
              const mappedAccounts = accounts.map(
                ({ id, name }: IAccountLevel) => ({
                  id,
                  label: name,
                  value: TreeLevels.ACCOUNTS,
                  checked: false,
                  disabled: false
                })
              );

              return {
                id: orgId,
                label: orgName,
                value: TreeLevels.ORG,
                checked: false,
                children: mappedAccounts,
                className: hideCheckbox.includes("org") ? "hideMe" : "",
                disabled: hideCheckbox.includes("org")
              };
            }
          );

          return {
            id: areaId,
            label: areaName,
            value: TreeLevels.AREA,
            checked: false,
            children: salesOrgs,
            className: hideCheckbox.includes("area") ? "hideMe" : "",
            disabled: hideCheckbox.includes("area")
          };
        }
      );

      return {
        id: regionId,
        label: regionName,
        value: TreeLevels.REGION,
        checked: false,
        children: salesArea,
        className: hideCheckbox.includes("region") ? "hideMe" : "",
        disabled: hideCheckbox.includes("region")
      };
    }
  );
};

export const convertDataToFilter = (data: ITreeNode[]) => {
  const filter = data.map(({ value, id }) => ({
    level: value,
    value: id
  }));

  return filter;
};

export const convertTreeDataToTableData = (data: ISalesRegion[]) => {
  return data.flatMap((region: ISalesRegion) =>
    region.sales_area.flatMap((area: ISalesArea) =>
      area.sales_organizations.flatMap((org: ISalesOrganization) =>
        org.accounts.map((account: IAccountLevel) => ({
          id: account.id,
          region: region.name,
          area: area.name,
          org: org.name,
          team: account.name
        }))
      )
    )
  );
};

export const countAccounts = (heirarchyTreeSelectedList: ISalesRegion[]) => {
  let accountCount = 0;
  for (const region of heirarchyTreeSelectedList) {
    if (region.sales_area) {
      for (const org of region.sales_area) {
        if (org.sales_organizations) {
          accountCount += org.sales_organizations.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.accounts.length,
            0
          );
        }
      }
    }
  }

  return accountCount;
};
export const markChecked = (tree: IHierarchyTreeData[], path: string[]) => {
  const matchingNode = tree.find((node) => node.label === path[0]);
  if (matchingNode) {
    if (matchingNode.children) {
      markChecked(matchingNode.children, path.slice(1));
    } else {
      matchingNode.checked = true;
    }
  }
};
export const markNodesChecked = (
  tree: IHierarchyTreeData[],
  path: string[][]
) => {
  if (!path.length) return;
  for (const row of path) {
    markChecked(tree, row);
  }
};

export const getFourLevelPaths = (data: ISalesRegion[]) => {
  const results: string[][] = [];
  for (const region of data) {
    for (const area of region.sales_area) {
      for (const organization of area.sales_organizations) {
        for (const account of organization.accounts) {
          results.push([
            region.name,
            area.name,
            organization.name,
            account.name
          ]);
        }
      }
    }
  }

  return results;
};
export const countCheckedLeafNodes = (tree: IHierarchyTreeData[]) => {
  if (!tree || !Array.isArray(tree)) {
    return 0;
  }
  let count = 0;
  const traverse = (node: IHierarchyTreeData) => {
    if (node.children && node.children.length > 0) {
      for (const childNode of node.children) {
        traverse(childNode);
      }
    } else {
      if (node.checked) {
        count++;
      }
    }
  };

  for (const rootNode of tree) {
    traverse(rootNode);
  }
  return count;
};
export const getStatusColor = (status: string) => {
  const { userStatusResponse } = staticStrings.userManagement;
  switch (status) {
    case userStatusResponse[0]:
      return { background: "#f1f8e8", color: "#74b816" };
    case userStatusResponse[1]:
      return { background: "#F1F3F5", color: "#97989B" };
    case userStatusResponse[2]:
      return { background: "#F8DADB", color: "#BB3236" };
  }
};

export const getStatusColorDomainDetails = (status: string) => {
  const { domainStatusColor } = staticStrings.ingestion.domains.domainDetails;
  return domainStatusColor[status as keyof DomainStatusResponse];
};

export const getStatusMessageDomainDetails = (status: string) => {
  const { domainStatusResponse } =
    staticStrings.ingestion.domains.domainDetails;

  return domainStatusResponse[status as keyof DomainStatusResponse];
};

export const getStatusColorAccountDetails = (status: string) => {
  const { accountStatusColor } = staticStrings.userManagement.acccounts;
  return accountStatusColor[status as keyof AccountStatusResponse];
};

export const getStatusMessageAccountDetails = (status: string) => {
  const { accountStatusResponse } = staticStrings.userManagement.acccounts;

  return accountStatusResponse[status as keyof AccountStatusResponse];
};

export const getStatusColorEloqua = (status: boolean) => {
  const { eloquaStatusColor } = staticStrings.integration.eloqua;
  return eloquaStatusColor[status.toString() as keyof EloquaStatusResponse];
};

export const getStatusMessageEloqua = (status: boolean) => {
  const { eloquaStatusResponse } = staticStrings.integration.eloqua;

  return eloquaStatusResponse[status.toString() as keyof EloquaStatusResponse];
};

export const getStatusColorEloquaSignature = (status: boolean) => {
  const { signatureStatusColor } = staticStrings.integration.eloqua;
  return status ? signatureStatusColor.false : signatureStatusColor.true;
};

export const getStatusMessageEloquaSignature = (status: boolean) => {
  const { signatureStatusResponse } = staticStrings.integration.eloqua;

  return status ? signatureStatusResponse.false : signatureStatusResponse.true;
};

export const getStatusMessage = (status: string) => {
  const { userStatusResponse, userStatusMessage } =
    staticStrings.userManagement;
  switch (status) {
    case userStatusResponse[0]:
      return userStatusMessage[0];
    case userStatusResponse[1]:
      return userStatusMessage[1];
    case userStatusResponse[2]:
      return userStatusMessage[2];
  }
};

export const addClassNames = (data: IHierarchyTreeData[]) => {
  const copiedObj = JSON.parse(JSON.stringify(data)); // Create a deep copy

  const addClassRecursively = (items: IHierarchyTreeData[]) => {
    for (const item of items) {
      if (item.children) {
        item.className = "hideMe";
        item.disabled = true;
        addClassRecursively(item.children);
      } else {
        item.disabled = false;
      }
    }
  };

  addClassRecursively(copiedObj);
  return copiedObj; // Return the modified copy
};
export const setInitialValues = (data: IHierarchyTreeData[]) => {
  const copiedObj = JSON.parse(JSON.stringify(data)); // Create a deep copy

  const setInitialValuesRecursively = (items: IHierarchyTreeData[]) => {
    for (const item of items) {
      item.checked = false;
      if (item.children) {
        setInitialValuesRecursively(item.children);
      }
    }
  };

  setInitialValuesRecursively(copiedObj);
  return copiedObj; // Return the modified copy
};
export const endsWithApprovedDomain = (str: string, substrings: string[]) => {
  for (const substring of substrings) {
    if (str.endsWith(substring)) {
      return true;
    }
  }
  return false;
};
export const getLabelToDisplay = (
  length: number,
  dropdownLabel: string,
  label: string,
  labelForMultipleSelect: string,
  itemlistLength = 99999,
  displayAll = false,
  selected = true
) => {
  if (length === 0) {
    return dropdownLabel;
  } else if (displayAll && itemlistLength === length) {
    return `${staticStrings.dropdownAllabel} ${labelForMultipleSelect}`;
  }
  return `${length} ${length === 1 ? label : labelForMultipleSelect}`;
};

export const findAccountObjById = (
  accountArr: IAccount[],
  accountId: string
) => {
  return accountArr.find((item) => item["id"] === accountId);
};

export const findAccountObjByPartyId = (
  accountArr: IAccount[],
  partyId: number
) => {
  return accountArr.find((item) => item["party_id"] === partyId);
};

export const momentCellParser = (value: string, format: string) => {
  if (value) return moment(value).format(format);
  return "---";
};

export const countUniqueValues = (
  accounts: ICreateTeamAccount[]
): Record<string, string[]> => {
  const counts: Record<string, string[]> = {};
  // Count unique values for each key
  accounts.forEach((account) => {
    for (const key in account) {
      if (account.hasOwnProperty(key) && key !== "team_id") {
        const value = account[key];
        if (!counts[key]) {
          counts[key] = [];
        }
        if (!counts[key].includes(value)) {
          counts[key].push(value);
        }
      }
    }
  });
  return counts;
};

export const arraysHaveSameValues = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  const string1 = sortedArr1.join(",");
  const string2 = sortedArr2.join(",");

  return string1 === string2;
};

export const isStringIncluded = (value: string, query: string) => {
  return value.toString().toLowerCase().includes(query.toLowerCase());
};
interface ObjectKeys {
  [key: string]: any;
}

export function sortByObjectName(
  arr: ObjectKeys[],
  nameKey: string,
  sortOrder: "asc" | "desc" = "asc"
): ObjectKeys[] {
  return arr.slice().sort((a, b) => {
    const nameA = a[nameKey].toUpperCase();
    const nameB = b[nameKey].toUpperCase();
    let compareBit = 0;

    if (nameA < nameB) {
      compareBit = -1;
    }
    if (nameA > nameB) {
      compareBit = 1;
    }

    if (sortOrder === "desc") {
      compareBit *= -1;
    }
    if (compareBit) return compareBit;

    return 0;
  });
}
/**
 * breakpointsAndFontSize is used to get font value in px with reference to the ref value sent.
 * The ref value is compared with breakpoints which are half of the window size currently
 */
const breakpointsAndFontSize: Record<number, IFontSize> = {
  768: { yaxis: 14, xaxis: 12, xAxisLabel: 11 },
  992: { yaxis: 12.6, xaxis: 12, xAxisLabel: 11 },
  1200: { yaxis: 11, xaxis: 10, xAxisLabel: 9 },
  1440: { yaxis: 9, xaxis: 8, xAxisLabel: 7 }
};

export const handleResizeChart = (
  ref: RefObject<HTMLDivElement>,
  setFontSize: (fontSize: IFontSize) => void
) => {
  const containerWidth = ref.current ? ref.current.offsetWidth : 0;
  for (const breakpoint of Object.keys(breakpointsAndFontSize)
    .map(Number)
    .sort((a, b) => a - b)) {
    if (containerWidth < breakpoint) {
      setFontSize(breakpointsAndFontSize[breakpoint]);
      return; // exit the loop once a match is found
    }
  }
  // Fallback for larger container widths
  setFontSize({ yaxis: 9, xaxis: 8, xAxisLabel: 7 });
};

// param: tableResponseData - response from the API
// param: headerSettings - Predefined columns for the table
// Returns Table headers from the keys that are coming from the API only and are in predefined headers.
export const getTableHeadingsForLocationSpecificTabs = (
  tableResponseData: ObjectKeys,
  headerSettings: Record<string, HeaderSetting>
): TableHeader[] => {
  const keys =
    tableResponseData.length > 0 ? Object.keys(tableResponseData[0]) : [];
  const filteredKeys = keys.filter((key) => key in headerSettings);
  const headers = filteredKeys.map((key) => {
    return {
      id: key,
      name: headerSettings[key]?.name || key,
      tooltip: headerSettings[key]?.tooltip,
      sortable: true,
      customClass: headerSettings[key]?.customClass
    };
  });
  return headers;
};

export const hasTableHeader = (
  input: string | undefined,
  tableHeaders: TableHeader[]
) => {
  return tableHeaders.some((header) => header.id === input);
};

export const getDataRefreshTextToDisplay = (date: string) => {
  return `Data last refreshed at ${date} UTC`;
};

export const getAccountReportingRefreshTextDisplay = (
  engagementScoredate: string,
  otherDataDate: string
) => {
  return `Engagement score calculated as of ${engagementScoredate} UTC / Other data last refreshed at ${otherDataDate} UTC`;
};

export const getEngagementScoreRefreshTextToDisplay = (date: string) => {
  return `Engagement score calculated as of ${date} UTC`;
};

export const getInitialAccountCardContent = () => {
  const ingestionAccountsCardImages = [Total, Active, Inactive];
  return ingestionAccountsCardImages.map((image, index) => {
    return {
      headerText: "---",
      bodyText: staticStrings.ingestion.accounts.cards[index],
      image: image ?? "",
      id: image + index
    };
  });
};

const sortDropDownList = (
  items: any,
  selectedItems: string[],
  sortKey: string = staticStrings.dropdownCheckbox.sortKeys.id,
  alphabetSortKey: string = staticStrings.dropdownCheckbox.sortKeys.name
) => {
  return [...items].sort((a: any, b: any) => {
    const aChecked = selectedItems.includes(a[sortKey]);
    const bChecked = selectedItems.includes(b[sortKey]);

    if (aChecked && !bChecked) return -1;
    if (!aChecked && bChecked) return 1;

    // If both items have the same checked status, sort alphabetically
    return a[alphabetSortKey].localeCompare(b[alphabetSortKey]);
  });
};

const getUniqueDropdownItemList = (prevList: any, currentList: any) =>
  Array.from(
    new Map(
      [...prevList, ...currentList].map((item) => [item.id, item])
    ).values()
  );

const sortTreeData = (nodes: IHierarchyTreeData[]): IHierarchyTreeData[] => {
  return nodes
    .map((node) => ({
      ...node,
      children: node.children ? sortTreeData(node.children) : undefined
    }))
    .sort((a, b) => {
      const aSelected = a.checked || a.partial;
      const bSelected = b.checked || b.partial;

      if (aSelected === bSelected) {
        return a.label.localeCompare(b.label);
      }
      return aSelected ? -1 : 1;
    });
};

export const getFirstNameLastName = (fullName: string) => {
  let firstName = "",
    lastName = "";
  const nameParts = fullName.split(/\s+/);
  if (nameParts.length === 1) {
    firstName = nameParts[0];
  } else {
    firstName = nameParts.slice(0, -1).join(" ");
    lastName = nameParts[nameParts.length - 1];
  }

  return { firstName, lastName };
};

const updateTableHeaders = <
  IAccountccountsTableHeader extends TableHeader | ITableHeader
>(
  headers: IAccountccountsTableHeader[],
  tableResponse: any
) => {
  return headers.map((header) => {
    if (header.id === "total_emails") {
      return {
        ...header,
        tooltip: `${staticStrings.account360.engagedPeople.emailHeaderTooltipMessage} ${tableResponse?.response?.time_taken_for_an_email_in_min} minutes.`
      };
    } else if (header.id === "total_meetings") {
      return {
        ...header,
        tooltip:
          staticStrings.account360.engagedPeople.meetingsHeaderTooltipMessage
      };
    }
    return header;
  });
};

export const HelperFunctions = {
  formatFromUTCDate,
  isToday,
  minutesToHours,
  convertDateToCommonFormat,
  getUserName,
  getUserDropDownTotalCheckBoxCount,
  capitalizeFirstLetter,
  getEndOfDay,
  convertDateToUTC,
  convertDataToTree,
  convertDataToFilter,
  countCheckedLeafNodes,
  getStatusColor,
  getStatusMessage,
  getLabelToDisplay,
  momentCellParser,
  findAccountObjById,
  findAccountObjByPartyId,
  countUniqueValues,
  arraysHaveSameValues,
  isStringIncluded,
  sortByObjectName,
  setInitialValues,
  sortDropDownList,
  getUniqueDropdownItemList,
  sortTreeData,
  getFirstNameLastName,
  updateTableHeaders
};
