import { IPeopleReportingTableResponse } from "@interfaces";
import { createSlice } from "@reduxjs/toolkit";
import {
  DEFAULT_API_INIT_STATE,
  DEFAULT_ORDER_BY_MY_ACTIVITIES,
  DEFAULT_SORT_BY_MY_ACTIVITIES
} from "../../../utils/constants/reducerConstants";

interface MyActivitiesInterface {
  orderByMyActivitiesReporting: string;
  sortByColumnMyActivitiesReporting: string;
  tableResponseMyActivitiesReporting: IPeopleReportingTableResponse | {};
  selectedPageMyActivitiesReporting: number;
  searchKeyWord: string;
  accountFilterList: string[];
}
const initialState: MyActivitiesInterface = {
  orderByMyActivitiesReporting: DEFAULT_ORDER_BY_MY_ACTIVITIES,
  sortByColumnMyActivitiesReporting: DEFAULT_SORT_BY_MY_ACTIVITIES,
  tableResponseMyActivitiesReporting: DEFAULT_API_INIT_STATE,
  selectedPageMyActivitiesReporting: 1,
  accountFilterList: [],
  searchKeyWord: ""
};
const myActivitiesReportSlice = createSlice({
  name: "myActivitiesReport",
  initialState,
  reducers: {
    setOrderByMyActivitiesReporting: (state, action) => {
      state.orderByMyActivitiesReporting = action.payload;
    },
    setSortByColumnMyActivitiesReporting: (state, action) => {
      state.sortByColumnMyActivitiesReporting = action.payload;
    },
    setTableResponseMyActivitiesReporting: (state, action) => {
      state.tableResponseMyActivitiesReporting = action.payload;
    },
    setSelectedPageMyActivitiesReporting: (state, action) => {
      state.selectedPageMyActivitiesReporting = action.payload;
    },
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setAccountFilterList: (state, action) => {
      state.accountFilterList = action.payload;
    },
    setAccountFilterListAndPageNumber: (state, action) => {
      state.selectedPageMyActivitiesReporting = 1;
      state.accountFilterList = action.payload;
    },
    setInitMyActivitiesReporting: () => {
      return initialState;
    }
  }
});
export const {
  setOrderByMyActivitiesReporting,
  setSortByColumnMyActivitiesReporting,
  setTableResponseMyActivitiesReporting,
  setSelectedPageMyActivitiesReporting,
  setInitMyActivitiesReporting,
  setAccountFilterList,
  setAccountFilterListAndPageNumber,
  setSearchKeyWord
} = myActivitiesReportSlice.actions;
export default myActivitiesReportSlice.reducer;
