import { useEffect, useState } from "react";
import DropDown from "@clayui/drop-down/lib/DropDown";
import ClayIcon from "@clayui/icon";
import spritemap from "@images/icons.svg";
import ClayButton from "@clayui/button";
import style from "./DropdownTreeView.module.scss";
import { staticStrings } from "@staticStrings";
import DropdownTreeSelect from "react-dropdown-tree-select";
import {
  IHierarchyTreeData,
  IHierarchyTreeProps,
  ITreeNode,
  IselectedNode
} from "@interfaces";
import { useSelector } from "react-redux";
import { RootState } from "store/configureStore";
import "react-dropdown-tree-select/dist/styles.css";
import "./DropdownTree.scss";
import { ContainerLoader } from "../container-loader/ContainerLoader";
import { HelperFunctions } from "@utils";
import CustomButton from "../CustomButton/CustomButton";

const TreeViewDropdown = ({
  showDropdown,
  showPartiallySelected = true,
  inlineSearchInput = true,
  keepTreeOnSearch = true,
  keepChildrenOnSearch = true,
  texts,
  showButton = true,
  dropdownLabel,
  handleCheckBoxSelection,
  disabled = false,
  submitButtonMessage,
  className,
  dropdownLabelClass,
  selectAllByDefault = false,
  setPrevSelection,
  prevSelection,
  dropdownId
}: IHierarchyTreeProps) => {
  let updatedNodes: IselectedNode[] = [];
  let isOnChangeClicked: boolean = false;
  const [open, setOpen] = useState(false);
  const [isClearFlag, setIsClearFlag] = useState(false);
  const { clear } = staticStrings.hierarchyTreeDropdown;
  const allValue = [
    {
      id: 1,
      label: staticStrings.dropdownAllabel,
      value: staticStrings.dropdownAllabel
    }
  ];
  const treeData = useSelector(
    (state: RootState) =>
      state.accountReport.hierarchyTreeResponse?.response?.data
  );
  const { heirarchyTreeResponseLoader } = useSelector(
    (state: RootState) => state.accountReport
  );

  const [dropdownRenderkey, setDropdownRenderkey] = useState(0);
  const [data, setData] = useState<IHierarchyTreeData[]>([]);
  const searchText = document.getElementsByClassName(
    "search"
  )[0] as HTMLInputElement;

  useEffect(() => {
    if (treeData && treeData.length > 0) {
      if (!selectAllByDefault) {
        setData(treeData);
        setPrevSelection(treeData);
      } else {
        const initialData = JSON.parse(JSON.stringify(treeData));
        const selectedNodes = updateData(initialData, allValue, true);
        setData(selectedNodes);
        setPrevSelection(selectedNodes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  const checkIfPartiallySelected = (
    children: IHierarchyTreeData[]
  ): boolean => {
    const hasChecked = children.some(
      (child) => child.checked === true || child.partial
    );
    const hasUnchecked = children.some((child) => child.checked === false);

    return hasChecked && hasUnchecked;
  };

  const updateChildren = (nodes: IHierarchyTreeData[], isChecked: boolean) => {
    nodes.forEach((node) => {
      node.checked = isChecked;
      if (node.children) {
        updateChildren(node.children, isChecked);
      }
      node.partial = false;
    });
  };

  const updateData = (
    nodes: IHierarchyTreeData[],
    updatedNodes: ITreeNode[],
    isChecked: boolean
  ) => {
    return nodes.map((node) => {
      const matchingNode =
        updatedNodes &&
        updatedNodes.length > 0 &&
        updatedNodes.find(
          (updatedNode: ITreeNode) => updatedNode.id === node.id
        );
      if (matchingNode) {
        node.checked = isChecked;
        if (node.children) updateChildren(node.children, isChecked);
      }
      if (node.children) {
        node.children = updateData(node.children, updatedNodes, isChecked);
        node.partial = checkIfPartiallySelected(node.children);
      }
      return node;
    });
  };

  const handleClearSearch = () => {
    if (searchText?.value) {
      searchText.value = "";
    }
    setDropdownRenderkey(dropdownRenderkey + 1);
  };

  const handleSubmit = () => {
    handleClearSearch();
    if (!isOnChangeClicked && !updatedNodes.length && !isClearFlag) {
      setOpen(!open);
      return;
    }

    const selectedNodes = updateData(
      JSON.parse(JSON.stringify(treeData)),
      updatedNodes,
      true
    );
    const count = HelperFunctions.countCheckedLeafNodes(selectedNodes);
    handleCheckBoxSelection(updatedNodes, count);
    setData(HelperFunctions.sortTreeData(selectedNodes));
    setPrevSelection(HelperFunctions.sortTreeData(selectedNodes));
    setOpen(!open);
    setIsClearFlag(false);
  };

  const handleOnChange = (currentNode: any, selectedNodes: any) => {
    // we have any here will be handled later
    updatedNodes = selectedNodes;
    isOnChangeClicked = true;
  };
  const handleClearSelection = () => {
    updatedNodes = [];
    const initialData = JSON.parse(JSON.stringify(treeData));
    const selectedNodes = updateData(initialData, allValue, false);
    handleClearSearch();
    setData(selectedNodes);
    setIsClearFlag(true);
  };

  return (
    <DropDown
      active={open}
      onActiveChange={(value: boolean) => {
        if (!value) {
          setData(prevSelection);
        }

        handleClearSearch();
        setOpen(value);
      }}
      className={style.treeDropDown}
      trigger={
        <ClayButton
          displayType="unstyled"
          className={style.treeDropDownBtn}
          onClick={() => {
            setData(prevSelection);

            handleClearSearch();
            setOpen(!open);
          }}
          disabled={disabled}
          id={dropdownId}
        >
          <div
            className={`${dropdownLabelClass ? dropdownLabelClass : ""} ${
              style.label
            }`}
          >
            {dropdownLabel}
          </div>
          {!open ? (
            <ClayIcon symbol="angle-down" spritemap={spritemap} />
          ) : (
            <ClayIcon symbol="angle-up" spritemap={spritemap} />
          )}
        </ClayButton>
      }
    >
      {heirarchyTreeResponseLoader && <ContainerLoader msgRequired={false} />}
      {!heirarchyTreeResponseLoader && (
        <div className={style.treeContainer}>
          <DropDown.Section>
            <div
              className={`${style.treeOuterWrap} ${className ? className : ""}`}
            >
              {treeData.length === 0 && <ContainerLoader msgRequired={false} />}
              {treeData?.length > 0 && data?.length > 0 && (
                <div className={style.treeInnerWrapReporting}>
                  <DropdownTreeSelect
                    key={dropdownRenderkey}
                    data={data}
                    showDropdown={showDropdown}
                    showPartiallySelected={showPartiallySelected}
                    inlineSearchInput={inlineSearchInput}
                    keepTreeOnSearch={keepTreeOnSearch}
                    keepChildrenOnSearch={keepChildrenOnSearch}
                    onChange={handleOnChange}
                    texts={texts}
                    id={"dropdown_list_" + dropdownId}
                    disablePoppingOnBackspace
                  />
                </div>
              )}
            </div>
          </DropDown.Section>
          {showButton && (
            <DropDown.Section>
              <div className={style.treeButtonContainer}>
                <CustomButton
                  displayType="secondary"
                  className={style.clearButton}
                  onClick={handleClearSelection}
                  id={`clear_button_${dropdownId}`}
                >
                  {clear}
                </CustomButton>

                <CustomButton
                  displayType="primary"
                  onClick={handleSubmit}
                  className={style.doneButton}
                  id={`submit_button_${dropdownId}`}
                >
                  {submitButtonMessage}
                </CustomButton>
              </div>
            </DropDown.Section>
          )}
        </div>
      )}
    </DropDown>
  );
};

export { TreeViewDropdown };
