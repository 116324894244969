// summarySlice.ts
import { IAccount, IUserInfo } from "@interfaces";
import { createSlice } from "@reduxjs/toolkit";
import { staticStrings } from "@staticStrings";
import moment from "moment";

const currentDateMoments = moment();

interface IAccount360Interface {
  accounts: IAccount[];
  accountId: string;
  endDate: string;
  startDate: string;
  selectedRange: string;
  selectedAccount: IAccount;
  selectedDivision: string;
  accountTeamUserInfo: IUserInfo;
  linkedInUsername: string;
}

const initialState: IAccount360Interface = {
  accounts: [],
  accountId: "",
  endDate: currentDateMoments.format(staticStrings.dateFormats.DMMMYY),
  startDate: currentDateMoments
    .subtract(89, "days")
    .format(staticStrings.dateFormats.DMMMYY),

  selectedRange: staticStrings.account360.activitytrends.range90Days,
  selectedAccount: { id: "", name: "", party_id: 0 },
  selectedDivision: "",
  accountTeamUserInfo: {},
  linkedInUsername: ""
  // ... other initial settings
};

const account360Slice = createSlice({
  name: "account360",
  initialState,
  reducers: {
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload?.id;
      state.selectedDivision = "";
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setSelectedRange: (state, action) => {
      state.selectedRange = action.payload;
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
    setSelectedDivision: (state, action) => {
      state.selectedDivision = action.payload;
    },
    setAccountTeamUserInfo: (state, action) => {
      state.accountTeamUserInfo = action.payload;
    },
    setLinkedInUsername: (state, action) => {
      state.linkedInUsername = action.payload;
    }

    // ... other actions
  }
});

export const {
  setAccounts,
  setAccountId,
  setStartDate,
  setEndDate,
  setSelectedRange,
  setSelectedAccount,
  setSelectedDivision,
  setAccountTeamUserInfo,
  setLinkedInUsername
} = account360Slice.actions;
export default account360Slice.reducer;
