import {
  DEFAULT_ORDER_BY_ELOQUASIGNATURE,
  DEFAULT_SORT_BY_ELOQUASIGNATURE
} from "@constants";
import { createSlice } from "@reduxjs/toolkit";
import { IIntegrationEloquaSignatureTableResponse } from "interfaces/eloqua.interface";

interface EloquaSignatureInterface {
  searchKeyWordSignature: string;
  tableResponse: IIntegrationEloquaSignatureTableResponse;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  checkedSignatures: string[];
}

const initialState: EloquaSignatureInterface = {
  searchKeyWordSignature: "",
  orderBy: DEFAULT_ORDER_BY_ELOQUASIGNATURE,
  sortByColumn: DEFAULT_SORT_BY_ELOQUASIGNATURE,
  selectedPage: 1,
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: []
    }
  },
  checkedSignatures: []
};

const eloquaSignatureSlice = createSlice({
  name: "eloquaSignature",
  initialState,
  reducers: {
    setSearchKeyWord: (state, action) => {
      state.searchKeyWordSignature = action.payload;
    },
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setCheckedSignatures: (state, action) => {
      state.checkedSignatures = action.payload;
    },
    setInitState: (state) => {
      return initialState;
    }
  }
});

export const {
  setSearchKeyWord,
  setOrderBy,
  setSelectedPage,
  setSortByColumn,
  setTableResponse,
  setCheckedSignatures,
  setInitState
} = eloquaSignatureSlice.actions;
export default eloquaSignatureSlice.reducer;
