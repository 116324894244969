import {
  DEFAULT_SELECTED_DIVISION,
  DEFAULT_SELECTED_TOP_VIEW
} from "@constants";
import { createSlice } from "@reduxjs/toolkit";

interface GraphViewInterface {
  selectedDivision: string;
  selectedTopView: string;
  selectedNodeId: string | null;
  freezeFlag: boolean;
  sourceId: string;
  targetId: string;
  emailTotalCount: number | null;
  emailCountInMinutes: string | null;
  meetingsTotalCount: number | null;
  meetingsCountInMinutes: string | null;
  activityInMinutes: string | null;
  nodeClickFlag: boolean;
}

const initialState: GraphViewInterface = {
  selectedDivision: DEFAULT_SELECTED_DIVISION,
  selectedTopView: DEFAULT_SELECTED_TOP_VIEW,
  selectedNodeId: null,
  freezeFlag: false,
  sourceId: "",
  targetId: "",
  emailTotalCount: null,
  emailCountInMinutes: null,
  meetingsCountInMinutes: null,
  meetingsTotalCount: null,
  activityInMinutes: null,
  nodeClickFlag: false
};

const graphViewSlice = createSlice({
  name: "graphView",
  initialState,
  reducers: {
    setSelectedDivision: (state, action) => {
      state.selectedDivision = action.payload;
    },
    setSelectedTopView: (state, action) => {
      state.selectedTopView = action.payload;
    },
    resetViews: (state) => {
      state.selectedDivision = DEFAULT_SELECTED_DIVISION;
      state.selectedTopView = DEFAULT_SELECTED_TOP_VIEW;
    },
    setSelectedNodeId: (state, action) => {
      state.selectedNodeId = action.payload;
    },
    setFreezeFlag: (state, action) => {
      state.freezeFlag = action.payload;
    },
    setSourceId: (state, action) => {
      state.sourceId = action.payload;
    },
    setTargetId: (state, action) => {
      state.targetId = action.payload;
    },
    setEmailTotalCount: (state, action) => {
      state.emailTotalCount = action.payload;
    },
    setEmailCountInMinutes: (state, action) => {
      state.emailCountInMinutes = action.payload;
    },
    setMeetingsTotalCount: (state, action) => {
      state.meetingsTotalCount = action.payload;
    },
    setMeetingsCountInMinutes: (state, action) => {
      state.meetingsCountInMinutes = action.payload;
    },
    setActivityInMinutes: (state, action) => {
      state.activityInMinutes = action.payload;
    },
    setNodeClickFlag: (state, action) => {
      state.nodeClickFlag = action.payload;
    }
  }
});

export const {
  setSelectedDivision,
  setSelectedTopView,
  resetViews,
  setSelectedNodeId,
  setFreezeFlag,
  setSourceId,
  setTargetId,
  setActivityInMinutes,
  setEmailCountInMinutes,
  setEmailTotalCount,
  setMeetingsCountInMinutes,
  setMeetingsTotalCount,
  setNodeClickFlag
} = graphViewSlice.actions;
export default graphViewSlice.reducer;
