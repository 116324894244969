import { staticStrings } from "@staticStrings";

// Line Chart
export const DEFAULT_CHART_CONTAINER_HEIGHT = 252;
export const DEFAULT_SECTION_HEIGHT_35 =
  DEFAULT_CHART_CONTAINER_HEIGHT * 0.3472; //  35% of 250
export const DEFAULT_SECTION_HEIGHT_50 = DEFAULT_CHART_CONTAINER_HEIGHT * 0.496; //50% of 250
export const SCALE_FACTOR_35 = 35;
export const SCALE_FACTOR_65 = 65;
export const ALL_OPTIONS = {
  id: 1,
  label: staticStrings.dropdownAllabel,
  value: staticStrings.dropdownAllabel,
  checked: false,
  className: "parent-node",
  expanded: true
};
export const convertPxToRem = 16;
export const detailsSectionEmailHeightInRem = 18;
export const remainingContentHeightInPx = 350;
export const PopUpConfigs =
  "popup=true,width=700,height=600,left=24,top=24,scrollbars,resizable,titlebar=yes,menubar=no,location=0,rel=noopener noreferrer";
export const gmailRedirectBaseURL =
  "https://mail.google.com/mail/u/?&view=cm&fs=1&tf=1";

export const googleCalendarBaseURL =
  "https://calendar.google.com/calendar/render?action=TEMPLATE";
export const newMeetLink = "https://meet.google.com/new";
export const meetPopUpConfigs =
  "popup=true,width=1100,height=650,left=24,top=24,scrollbars,resizable,titlebar=yes,menubar=no,location=0";
export const newContactLink = "https://contacts.google.com/new";
export const contactPopUpConfigs =
  "popup=true,width=800,height=700,left=24,top=24,scrollbars,resizable,titlebar=yes,menubar=no,location=0";

export const MAX_EMAIL_LENGTH = 30;
