import React, { useEffect, useRef, useState } from "react";
import styles from "./FilterIngestionPopUp.module.scss";
import ClayModal from "@clayui/modal";
import ClayLayout from "@clayui/layout";
import { FormControl } from "@components/Form/FormControl";
import { Formik, FormikValues, useFormikContext } from "formik";
import ClayForm from "@clayui/form";
import * as Yup from "yup";
import { staticStrings } from "@staticStrings";
import { useSelector } from "react-redux";
import { RootState } from "store/configureStore";
import { capitalizeFirstLetter } from "../../utils/HelperFunctions";
import {
  IFilterIngestionTableData,
  IFormData
} from "interfaces/filterIngestion.interface";
import { SalesModal } from "@common-components";
import CustomButton from "../../common-ui-modules/common-components/CustomButton/CustomButton";
import { staticIds } from "@staticIds";

interface IFilterIngestionPopUpProps {
  open: boolean;
  observer: any;
  onOpenChange: (value: boolean) => void;
  popUpTitle: string;
  rowData?: IFilterIngestionTableData;
  onSubmit: (values: IFormData) => void;
}
const FilterIngestionPopUp: React.FC<IFilterIngestionPopUpProps> = ({
  open,
  observer,
  popUpTitle,
  onOpenChange,
  rowData,
  onSubmit
}) => {
  const formRef = useRef(null);
  const [formData, setFormData] = useState<IFormData>({
    pattern: "",
    type: "",
    field: ""
  });
  const [typeData, setTypeData] = useState<string[]>([]);
  const [disableFieldFlag, setDisableFieldFlag] = useState<boolean>(false);
  const { filterOptionsResponse, isSavingFilter } = useSelector(
    (state: RootState) => state.filterIngestion
  );
  const [displayWarning, setDisplayWarning] = useState<boolean>(false);
  const validationSchema = Yup.object().shape(
    {
      pattern: Yup.string().required("Pattern is required").max(128),
      type: Yup.string().required("Type is required"),
      field: Yup.string().required("Field is required")
    },
    []
  );

  const getDropDownValues = (selectedType: string) => {
    const fieldArray: string[] = [];
    for (let key in filterOptionsResponse.response) {
      if (filterOptionsResponse.response.hasOwnProperty(key)) {
        if (key.toLowerCase() === selectedType.toLowerCase()) {
          filterOptionsResponse.response[key].forEach((fieldOption: string) => {
            fieldArray.push(capitalizeFirstLetter(fieldOption));
          });
        }
      }
    }
    return fieldArray.length > 0
      ? fieldArray
      : [staticStrings.filterIngestion.noData];
  };

  useEffect(() => {
    const typeDataArray = [];
    for (let key in filterOptionsResponse.response) {
      if (filterOptionsResponse.response.hasOwnProperty(key)) {
        typeDataArray.push(capitalizeFirstLetter(key));
      }
    }
    setTypeData(typeDataArray);
  }, [filterOptionsResponse]);

  useEffect(() => {
    if (rowData) {
      setFormData({
        pattern: rowData.pattern,
        type: capitalizeFirstLetter(rowData.filter_type),
        field: capitalizeFirstLetter(rowData.filter_field),
        filter_id: rowData.id
      });
    } else {
      setFormData({ pattern: "", type: "", field: "" });
    }
  }, [rowData]);

  const AutoHandleOnChangeForm = () => {
    // Grab values and submitForm from context
    const { values, setFieldValue } = useFormikContext<FormikValues>();

    React.useEffect(() => {
      if (
        values.type !== "" &&
        values.type.toUpperCase() === staticStrings.filterIngestion.contacts
      ) {
        setFieldValue(
          "field",
          capitalizeFirstLetter(
            filterOptionsResponse.response[values.type.toUpperCase()][0]
          )
        );
        setDisplayWarning(true);
        setDisableFieldFlag(true);
      } else {
        setDisplayWarning(false);
        setDisableFieldFlag(false);
      }
    }, [values.type, setFieldValue]);

    return null;
  };

  return (
    <div className={`${styles.cardDiv} `}>
      {open && (
        <SalesModal
          modalId={staticIds.filterIngestion.addModal}
          observer={observer}
          open={open}
          title={popUpTitle}
          disableAutoClose={false}
          onClose={() => {
            onOpenChange(false);
          }}
        >
          <Formik
            initialValues={formData}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            innerRef={formRef}
            validateOnMount={true}
          >
            {(formik: any) => (
              // @ts-ignore
              <ClayForm
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}
              >
                <ClayModal.Body>
                  <ClayLayout.Row>
                    <ClayLayout.Col>
                      <FormControl
                        control="dropdown"
                        name="type"
                        label="Type"
                        placeholder="Select"
                        filteredList={typeData}
                      />
                    </ClayLayout.Col>
                  </ClayLayout.Row>
                  <ClayLayout.Row>
                    <ClayLayout.Col>
                      <FormControl
                        control="dropdown"
                        name="field"
                        label="Field"
                        placeholder="Select"
                        filteredList={getDropDownValues(formik.values.type)}
                        disabled={disableFieldFlag || !formik.values.type}
                      />
                    </ClayLayout.Col>
                  </ClayLayout.Row>
                  <ClayLayout.Row>
                    <ClayLayout.Col>
                      <div className={styles.patternField}>
                        <FormControl
                          control="text"
                          name="pattern"
                          label="Pattern"
                          placeholder="Enter"
                        />
                      </div>
                    </ClayLayout.Col>
                  </ClayLayout.Row>
                  {displayWarning && (
                    <ClayLayout.Col
                      className={`form-group-sm has-error ${styles.warningContainer}`}
                    >
                      <ClayForm.FeedbackItem>
                        <div className={styles.warningContainer}>
                          <span
                            className={`${styles.warningText} ${styles.fontWeight600}`}
                          >
                            {staticStrings.filterIngestion.warning}
                          </span>
                          <span className={` ${styles.warningText}`}>
                            {staticStrings.filterIngestion.warningInfoText1}
                          </span>
                          <span
                            className={`${styles.warningText} ${styles.fontWeight600}`}
                          >
                            {staticStrings.filterIngestion.warningInfoText2}
                          </span>

                          <span className={` ${styles.warningText}`}>
                            {staticStrings.filterIngestion.warningInfoText3}
                          </span>
                        </div>
                      </ClayForm.FeedbackItem>
                    </ClayLayout.Col>
                  )}
                </ClayModal.Body>

                <ClayModal.Footer
                  last={
                    <div>
                      <CustomButton
                        displayType="secondary"
                        onClick={() => {
                          onOpenChange(false);
                        }}
                        id="save_filter_cancel"
                      >
                        {staticStrings.filterIngestion.cancel}
                      </CustomButton>

                      <CustomButton
                        displayType="primary"
                        className={styles.filterIngestionClayModalAddEditButton}
                        disabled={isSavingFilter}
                        type="submit"
                        id="save_filter_submit"
                      >
                        {rowData
                          ? staticStrings.filterIngestion.save
                          : staticStrings.filterIngestion.add}
                      </CustomButton>
                    </div>
                  }
                ></ClayModal.Footer>
                <AutoHandleOnChangeForm />
              </ClayForm>
            )}
          </Formik>
        </SalesModal>
      )}
    </div>
  );
};

export default FilterIngestionPopUp;
