import { axiosHttp } from "@common-services";
import IngestionAccountHeader from "@components/IngestionAccountsHeader/IngestionAccountsHeader";
import IngestionAccountsTable from "@components/IngestionAccountsTable/IngestionAccountsTable";
import { PATHS } from "@constants";
import { RootState } from "@store/configureStore";
import {
  setIngestionAccountsCardContent,
  setTableResponse
} from "@store/ingestionAccounts/ingestionAccounts.slice";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { predefinedValues, tableHeader } from "./IngestionAccountsConstants";
import Total from "@images/ingestionAccounts/total.svg";
import Active from "@images/userManagement/UserManagementActive.svg";
import Inactive from "@images/userManagement/UserManagementInactive.svg";
import { staticStrings } from "@staticStrings";
import { SalesAlert, SalesModal } from "@common-components";
import { useModal } from "@clayui/core";
import { staticIds } from "@staticIds";
import { Formik, FormikValues, useFormikContext } from "formik";
import ClayLayout from "@clayui/layout";
import ClayModal from "@clayui/modal";
import ClayForm, { ClayCheckbox } from "@clayui/form";
import * as Yup from "yup";
import styles from "./IngestionAccounts.module.scss";
import { FormControl } from "@components/Form/FormControl";
import CustomButton from "../../../../common-ui-modules/common-components/CustomButton/CustomButton";
import { IFiltersState } from "@interfaces";

interface IFormData {
  linkedin_username: string;
}
const IngestionAccounts: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [controller, setController] = useState(new AbortController());
  const { observer, onOpenChange, open } = useModal();
  const [disableSubmitFlag, setDisableSubmitFlag] = useState(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [payload, setPayload] = useState<IFiltersState[]>([]);
  const formRef = useRef(null);
  const {
    sortByColumn,
    orderBy,
    selectedPage,
    searchKeyWord,
    isActive,
    linkedin_username,
    tableRowIsActive,
    selectedAccountId
  } = useSelector((state: RootState) => state.ingestionAccounts);

  const [userNameExistFlag, setUserNameExistFlag] = useState(false);
  const formData = {
    linkedin_username: linkedin_username || ""
  };
  const validationSchema = Yup.object().shape(
    {
      linkedin_username: Yup.string()
    },
    []
  );
  const dispatch = useDispatch();

  const ingestionAccountsCardImages = [Total, Active, Inactive];

  const endpoints = PATHS.INGESTION.ACCOUNTS;
  const getUrlParams = useCallback(() => {
    let apiUrl =
      `${endpoints}?` +
      `&sortByColumn=${sortByColumn}&orderBy=${orderBy}` +
      `&pageNo=${selectedPage}&pageSize=${predefinedValues.pageSize}&search=${encodeURIComponent(searchKeyWord)}`;
    if (isActive) {
      apiUrl += `&isActive=${isActive}`;
    }

    return apiUrl;
  }, [sortByColumn, orderBy, selectedPage, endpoints, searchKeyWord, isActive]);

  const fetchIngestionAccounts = useCallback(() => {
    //api call for table data
    const newController = new AbortController();
    setController(newController);

    const apiUrl = getUrlParams();
    if (apiUrl) {
      setIsLoading(true);
      axiosHttp
        .post(
          apiUrl,
          {
            filters: payload
          },
          { signal: newController.signal }
        )
        .then((response) => {
          dispatch(setTableResponse(response.data));
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.code !== "ERR_CANCELED") {
            setIsLoading(false);
            console.error("Error:", error);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getUrlParams, payload]);

  const fetchAccountCardContent = useCallback(() => {
    const apiUrl = `${PATHS.INGESTION.ACCOUNT_SUMMARY}`;
    axiosHttp
      .get(apiUrl)
      .then((response) => {
        const data = response.data.response;
        const accountsCardNumbers = [
          data.total,
          data.total_active,
          data.total_inactive
        ];
        dispatch(
          setIngestionAccountsCardContent(
            ingestionAccountsCardImages.map((image, index) => {
              return {
                headerText: accountsCardNumbers[index]?.toString() ?? "---",
                bodyText: staticStrings.ingestion.accounts.cards[index],
                image: image ?? "",
                id: image + index
              };
            })
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const AutoHandleOnChangeForm = () => {
    // Grab values and submitForm from context
    const { values } = useFormikContext<FormikValues>();

    React.useEffect(() => {
      if (
        (linkedin_username && values.linkedin_username === linkedin_username) ||
        !values?.linkedin_username.trim()?.length
      ) {
        setDisableSubmitFlag(true);
      } else {
        setDisableSubmitFlag(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.linkedin_username]);

    return null;
  };

  const handleLinkedInUpdate = async (values: IFormData) => {
    const apiUrl = PATHS.INGESTION.ACCOUNTS_V1;
    let payload: any = {
      account_id: selectedAccountId,
      is_active: tableRowIsActive,
      linkedin_user_name: values.linkedin_username.trim()
    };
    if (!userNameExistFlag) {
      payload = { ...payload, linkedin_user_name: null };
    }
    axiosHttp
      .patch(apiUrl, payload)
      .then((response) => {
        setAlertMessage(response?.data?.response);
        setAlertOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        const timer = setTimeout(() => setAlertOpen(false), 3000);
        fetchIngestionAccounts();
        return () => clearTimeout(timer);
      });
    onOpenChange(false);
  };
  useEffect(() => {
    // Cleanup function to abort the previous request when the component unmounts or controller changes
    return () => controller.abort();
  }, [controller]);

  useEffect(() => {
    setUserNameExistFlag(!!linkedin_username);
  }, [linkedin_username, open]);

  useEffect(() => {
    fetchAccountCardContent();
  }, [fetchAccountCardContent]);

  useEffect(() => {
    fetchIngestionAccounts();
  }, [fetchIngestionAccounts]);

  return (
    <div>
      <IngestionAccountHeader setPayload={setPayload} currPayload={payload} />
      <IngestionAccountsTable
        tableHeader={tableHeader}
        isLoading={isLoading}
        fetchIngestionAccounts={fetchIngestionAccounts}
        fetchAccountCardContent={fetchAccountCardContent}
        linkedInModalOnOpenChange={onOpenChange}
        setAlertMessage={setAlertMessage}
        setAlertOpen={setAlertOpen}
      />
      {open && (
        <SalesModal
          modalId={staticIds.ingestion.accounts.updateLinkedinModal}
          observer={observer}
          open={open}
          title={staticStrings.ingestion.accounts.linkedModalHeader}
          disableAutoClose={false}
          onCancel={() => {
            onOpenChange(false);
          }}
          onClose={() => {
            onOpenChange(false);
          }}
        >
          <Formik
            initialValues={formData}
            onSubmit={handleLinkedInUpdate}
            validationSchema={validationSchema}
            innerRef={formRef}
            validateOnMount={true}
          >
            {(formik: any) => (
              // @ts-ignore
              <ClayForm
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}
              >
                <ClayModal.Body>
                  <ClayLayout.Row>
                    <ClayLayout.Col>
                      <div className={styles.accountUpdateContainer}>
                        <div
                          className={`${styles.listItem} ${
                            !userNameExistFlag
                              ? styles.accountUpdateContainerCheckBoxStyled
                              : styles.accountUpdateContainerCheckBoxUnStyled
                          }`}
                        >
                          <ClayCheckbox
                            checked={!userNameExistFlag}
                            onChange={() => {
                              setUserNameExistFlag(() => !userNameExistFlag);
                            }}
                          />
                          <div className="content">
                            <div className={styles.textContent}>
                              {staticStrings.ingestion.accounts.unknown}
                            </div>
                          </div>
                        </div>

                        <div
                          className={`${styles.listItem} ${
                            userNameExistFlag
                              ? styles.accountUpdateContainerCheckBoxStyled
                              : styles.accountUpdateContainerCheckBoxUnStyled
                          } ${styles.accountUpdateContainer}`}
                        >
                          <ClayCheckbox
                            checked={userNameExistFlag}
                            onChange={() => {
                              setUserNameExistFlag(() => !userNameExistFlag);
                            }}
                          />
                          <div className="content">
                            <div className={styles.textContent}>
                              {staticStrings.ingestion.accounts.linkedInUrl}
                            </div>
                          </div>
                          <div className={styles.inputContainer}>
                            <FormControl
                              control="text"
                              name="linkedin_username"
                              label={staticStrings.integration.eloqua.name}
                              placeholder={
                                staticStrings.integration.eloqua.enter
                              }
                              disabled={!userNameExistFlag}
                            />
                          </div>
                        </div>
                      </div>
                    </ClayLayout.Col>
                  </ClayLayout.Row>
                </ClayModal.Body>
                <ClayModal.Footer
                  last={
                    <div>
                      <CustomButton
                        displayType="secondary"
                        onClick={() => {
                          onOpenChange(false);
                        }}
                        id={staticIds.ingestion.accounts.cancelButton}
                      >
                        {staticStrings.filterIngestion.cancel}
                      </CustomButton>

                      <CustomButton
                        displayType="primary"
                        className={styles.updateButton}
                        type="submit"
                        id={staticIds.ingestion.accounts.updateButton}
                        disabled={disableSubmitFlag && userNameExistFlag}
                      >
                        {staticStrings.commonTexts.update}
                      </CustomButton>
                    </div>
                  }
                ></ClayModal.Footer>
                <AutoHandleOnChangeForm />
              </ClayForm>
            )}
          </Formik>
        </SalesModal>
      )}
      {alertOpen && (
        <div className={styles.statusAlert}>
          <SalesAlert setAlertOpen={setAlertOpen} message={alertMessage} />
        </div>
      )}
    </div>
  );
};

export default IngestionAccounts;
