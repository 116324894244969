import { IIngestionAccountsCardDetails } from "interfaces/ingestionAccounts.interface";
import styles from "./IngestionAccountsHeader.module.scss";
import SummaryBox from "@components/SummaryBox/SummaryBox";
import { staticIds } from "@staticIds";
import { staticStrings } from "@staticStrings";
import { useEffect, useState } from "react";
import { RootState } from "@store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsActive,
  setSelectedPage
} from "@store/ingestionAccounts/ingestionAccounts.slice";
import { TreeViewDropdown } from "../../common-ui-modules/common-components/dropdown-tree-view/TreeViewDropdown";
import { IFiltersState, IHierarchyTreeData, ITreeNode } from "@interfaces";
import {
  convertDataToFilter,
  convertDataToTree,
  HelperFunctions
} from "../../utils/HelperFunctions";
import {
  setHeirarchyTreeResponseLoader,
  setHierarchyTreeResponse
} from "@store/reporting/account/accountReport.slice";
import { axiosHttp } from "@common-services";
import { PATHS } from "@constants";
import { ALL_OPTIONS } from "../../utils/constants/defaultConstants";

interface IngestionAccountsHeaderProps {
  setPayload: (state: IFiltersState[]) => void;
  currPayload: IFiltersState[];
}

const IngestionAccountHeader: React.FC<IngestionAccountsHeaderProps> = ({
  setPayload,
  currPayload
}) => {
  const [clickedCard, setClickedCard] = useState<number>(0);
  const dispatch = useDispatch();
  const {
    accounts,
    all,
    allAccounts,
    accountLabel,
    accountsLabel,
    searchPlaceHolder
  } = staticStrings.reporting;

  const [prevSelection, setPrevSelection] = useState<IHierarchyTreeData[]>([]);
  const [label, setLabel] = useState(accountsLabel);
  const { ingestionAccountsCardContent } = useSelector(
    (state: RootState) => state.ingestionAccounts
  );

  const handleCardClick = (index: number) => {
    setClickedCard(index);
    dispatch(setSelectedPage(1));
    switch (index) {
      case 0:
        return dispatch(setIsActive(""));
      case 1:
        return dispatch(setIsActive(staticStrings.ingestion.accounts.active));
      case 2:
        return dispatch(setIsActive(staticStrings.ingestion.accounts.inactive));
      default:
        return dispatch(setIsActive(""));
    }
  };
  const handleCheckBoxSelection = (data: ITreeNode[], count: number) => {
    const payload =
      data.length === 0 || (data.length === 1 && data[0].value === all)
        ? []
        : convertDataToFilter(data);
    if (data.length === 0) setLabel(accountsLabel);
    else if (data[0] && data[0].value === all) {
      setLabel(allAccounts);
    } else if (count === 1) {
      setLabel(`${count} ${accountLabel}`);
    } else {
      setLabel(`${count} ${accountsLabel}`);
    }
    dispatch(setSelectedPage(1));
    setPayload(payload);
  };
  useEffect(() => {
    dispatch(setHeirarchyTreeResponseLoader(true));
    axiosHttp
      .post(`${PATHS.REPORTING.HIERARCHY_TREE_LIST}`, {
        include_inactive_accounts: true,
        include_accounts_valid_for_ingestion: true
      })
      .then((response) => {
        const result = response.data;
        const treeData = convertDataToTree(result.response.data, []);
        result.response.data = HelperFunctions.sortTreeData([
          { ...ALL_OPTIONS, children: treeData }
        ]);
        dispatch(setHierarchyTreeResponse(result));
        dispatch(setHeirarchyTreeResponseLoader(false));
      })
      .catch((error) => {
        console.error("Error:", error);
        dispatch(setHeirarchyTreeResponseLoader(false));
      });
  }, [dispatch]);

  return (
    <div className={styles.accountCardAndSearchSection}>
      <div className={styles.accountCardOuterContainer}>
        {ingestionAccountsCardContent?.map(
          (data: IIngestionAccountsCardDetails, index: number) => {
            const isActive = clickedCard === index;

            return (
              <div
                className={
                  isActive ? styles.accountCardActive : styles.accountCard
                }
                key={data.id}
                onClick={() => handleCardClick(index)}
              >
                <div className={styles.accountCardExtraPadding}>
                  <SummaryBox
                    headerText={data.headerText}
                    bodyText={data.bodyText}
                    image={data.image}
                    summaryCardId={`${staticIds.ingestion.accounts.card}${index}`}
                  />
                </div>
              </div>
            );
          }
        )}
      </div>
      <div className={styles.searchAccountContainer}>
        <div className={styles.treeDropdown}>
          <div className="text">{accounts.treeDropdownlabel}</div>
          <TreeViewDropdown
            showDropdown="always"
            texts={{
              inlineSearchPlaceholder: searchPlaceHolder,
              noMatches: staticStrings.commonTexts.noRecordsFound
            }}
            dropdownLabel={label}
            handleCheckBoxSelection={handleCheckBoxSelection}
            page="ingestionAccounts"
            submitButtonMessage={staticStrings.hierarchyTreeDropdown.done}
            prevSelection={prevSelection}
            setPrevSelection={setPrevSelection}
            dropdownId="account_filter_dropdown"
          />
        </div>
      </div>
    </div>
  );
};

export default IngestionAccountHeader;
