import { Sorting } from "@clayui/core/lib/table/context";
import {
  DEFAULT_ORDER_BY_DOMAIN_INGESTION,
  DEFAULT_SORT_BY_DOMAIN_INGESTION
} from "../../../../utils/constants/reducerConstants";
import { ITableHeader } from "interfaces/filterIngestion.interface";

export const predefinedValues = {
  pageSize: 10
};
export const predefinedValuesAccounts = {
  pageSize: 20
};

export const tableHeader: ITableHeader[] = [
  {
    id: "account_name",
    name: "Account",
    sortable: true
  },
  {
    id: "sales_area",
    name: "Sales Area",
    sortable: true
  },
  {
    id: "sales_org",
    name: "Sales Org",
    sortable: true
  },
  {
    id: "sales_region",
    name: "Sales Region",
    sortable: true
  },
  {
    id: "no_of_domains",
    name: "No. of Domains",
    sortable: true
  },
  {
    id: "no_of_emails",
    name: "No. of Email Ids",
    sortable: true
  },

  {
    id: "actions",
    name: "Actions",
    sortable: false
  }
];

export const defaultSort: Sorting = {
  column: DEFAULT_SORT_BY_DOMAIN_INGESTION,
  direction: DEFAULT_ORDER_BY_DOMAIN_INGESTION
};
