// PermissionChecker.tsx
import { usePermission } from "@utils";
import React, { ReactNode } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/configureStore";

interface PermissionCheckerProps {
  userPermissions: string[];
  requiredPermission?: string | string[];
  children: ReactNode;
  omitChecking?: boolean;
}

const PermissionChecker: React.FC<
  ConnectedProps<typeof connector> & PermissionCheckerProps
> = ({
  userPermissions,
  requiredPermission,
  omitChecking = false,
  children
}) => {
  // Check if the user has the required permission
  let hasPermission = usePermission(requiredPermission, omitChecking);

  return hasPermission ? <div>{children}</div> : <></>;
};

// Map Redux state to component props
const mapStateToProps = (state: RootState) => ({
  userPermissions: state.config.userPermissions
});

const connector = connect(mapStateToProps);

export default connector(PermissionChecker);
