import {
  DEFAULT_ORDER_BY_ACCOUNT360_DOMAINS,
  DEFAULT_SORT_BY_ACCOUNT360_DOMAINS
} from "../../../utils/constants/reducerConstants";
import { createSlice } from "@reduxjs/toolkit";
import {
  IDomainsCardDetails,
  IIngestionDomainDetailsTableResponse
} from "interfaces/domains.interface";

interface DomainsInterface {
  domainDetailsCardContent: IDomainsCardDetails[];
  emailCardContent: IDomainsCardDetails[];
  searchKeyWord: string;
  tableResponse: IIngestionDomainDetailsTableResponse;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  isDomain: boolean;
}

const initialState: DomainsInterface = {
  domainDetailsCardContent: [],
  emailCardContent: [],
  searchKeyWord: "",
  orderBy: DEFAULT_ORDER_BY_ACCOUNT360_DOMAINS,
  sortByColumn: DEFAULT_SORT_BY_ACCOUNT360_DOMAINS,
  selectedPage: 1,
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: { account_name: "", domains: [], party_id: "" }
    }
  },

  isDomain: true
};

const domainsSlice = createSlice({
  name: "account360Domains",
  initialState,
  reducers: {
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setDomainDetailsCardContent: (state, action) => {
      state.domainDetailsCardContent = action.payload;
    },
    setEmailCardContent: (state, action) => {
      state.emailCardContent = action.payload;
    },
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setIsDomain: (state, action) => {
      state.isDomain = action.payload;
    },
    setInitDomainDetails: () => {
      return initialState;
    }
  }
});

export const {
  setSearchKeyWord,
  setDomainDetailsCardContent,
  setEmailCardContent,
  setOrderBy,
  setSelectedPage,
  setSortByColumn,
  setTableResponse,
  setIsDomain,
  setInitDomainDetails
} = domainsSlice.actions;
export default domainsSlice.reducer;
