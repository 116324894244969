import React, { useEffect, useRef, useState } from "react";
import ClayButton from "@clayui/button";
import { HelperFunctions } from "@utils";
import styles from "./ProfileCard.module.scss";
import googleIcon from "@images/google-icon.svg";
import linkedInIcon from "@images/LinkedinRoundedProfileCard.svg";
import LoadingGif from "@images/loading-circle-black.gif";
import { ReactComponent as PhoneIcon } from "@images/phone-icon.svg";
import { ReactComponent as MailIcon } from "@images/mail-icon.svg";
import { ReactComponent as CopyIcon } from "@images/copy-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/configureStore";
import { UserImage } from "../UserImage/UserImage";
import { staticStrings } from "@staticStrings";
import { Tooltip } from "react-tooltip";
import { IAccount } from "@interfaces";
import { axiosHttp } from "@common-services";
import { PATHS } from "@constants";
import { setUserContacts } from "@store/config/config.slice";
import { setSelectedUserEmail } from "@store/account360/accountTeam/accountTeam.slice";
import { setRefreshPage } from "@store/account360/engagedPeople/engagedPeople.slice";

interface ProfileCardProps {
  name: string;
  title: string;
  isActive: boolean;
  imageUrl?: string;
  linkedInUrl?: string;
  email: string;
  phone?: string;
  selectedAccount: IAccount;
  isInternalUser?: boolean;
  setGetFeedbackFlag?: (flag: boolean) => void;
  setActivePage?: (page: number) => void;
  linkedInStatus?: string;
  parentPage: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name,
  title,
  imageUrl,
  isActive,
  linkedInUrl,
  email,
  phone,
  selectedAccount,
  isInternalUser = false,
  setGetFeedbackFlag,
  setActivePage,
  linkedInStatus,
  parentPage
}) => {
  const [tooltipMessage, setTooltipMessage] = useState(
    staticStrings.profileCard.copyEmail
  );

  const { userContacts } = useSelector((state: RootState) => state.config);

  const nameRef = useRef<HTMLDivElement | null>(null);
  const emailRef = useRef<HTMLDivElement | null>(null);
  const [maxWidth, setMaxWidth] = useState("10rem");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (nameRef?.current && emailRef?.current) {
      const nameWidth = nameRef.current.offsetWidth;
      const emailWidth = emailRef.current.offsetWidth;
      setMaxWidth(`${Math.max(nameWidth, emailWidth)}px`);
    }
  }, [name]);

  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setTooltipMessage(staticStrings.profileCard.copied);
      })
      .catch((err) => {
        setTooltipMessage(
          `${staticStrings.notification_messages.error_while_copy} ${err}`
        );
      });
  };

  const handleSaveContact = () => {
    setIsLoading(true);
    const { firstName, lastName } = HelperFunctions.getFirstNameLastName(
      name?.length ? name : email
    );

    axiosHttp
      .post(PATHS.USER_CONFIG.USER_GOOGLE_CONTACTS, {
        user_info: {
          first_name: firstName,
          last_name: lastName,
          phone_number: phone,
          email_address: email,
          account_name: isInternalUser
            ? staticStrings.profileCard.broadcom
            : selectedAccount.name,
          title: title
        }
      })
      .then((response) => {
        if (response.status === 201)
          dispatch(setUserContacts([...userContacts, email]));

        setIsLoading(false);
      })
      .catch((err: any) => {
        console.error("Error: ", err);
        setIsLoading(false);
      });
  };

  const handleLinkedInPop = () => {
    dispatch(setSelectedUserEmail(email));
    setActivePage && setActivePage(0);
    if (
      linkedInStatus === null ||
      (linkedInStatus &&
        ![
          staticStrings.linkedInFeedBackModal.feedbackStatus.accept,
          staticStrings.linkedInFeedBackModal.feedbackStatus.reject
        ].includes(linkedInStatus))
    ) {
      dispatch(setRefreshPage(parentPage));
      setGetFeedbackFlag && setGetFeedbackFlag(true);
    }

    window.open(`${linkedInUrl}`, "_blank", "noopener noreferrer");
  };
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardContainerHeader}>
        <div className={styles.profilePic}>
          <UserImage imageLink={imageUrl} is_active={isActive} />
        </div>
        <div className={styles.cardContainerNameAndTitle}>
          <p className={styles.name} ref={nameRef}>
            {name?.length ? name : email}
          </p>
          <p className={styles.title} style={{ maxWidth: maxWidth }}>
            {title?.length ? title : "---"}
          </p>
        </div>
      </div>
      <div className={styles.links}>
        <ClayButton
          className={styles.linksButton}
          displayType="link"
          onClick={() => handleSaveContact()}
          disabled={userContacts?.includes(email) || isLoading}
        >
          <img src={googleIcon} className={styles.svgIcon} alt="google-icon" />
          {userContacts?.includes(email)
            ? staticStrings.profileCard.contactAdded
            : staticStrings.profileCard.addContact}
          {isLoading && (
            <img
              className={styles.loaderIcon}
              src={LoadingGif}
              alt={"Loading ..."}
            />
          )}
        </ClayButton>
        {linkedInUrl?.length ? (
          <ClayButton
            className={styles.linksButton}
            displayType="link"
            onClick={() => handleLinkedInPop()}
          >
            <img
              src={linkedInIcon}
              className={styles.svgIcon}
              alt="linkedin-icon"
            />
            {staticStrings.profileCard.linkedIn}
          </ClayButton>
        ) : null}
      </div>
      <div className={styles.contactInfo}>
        <div className={styles.emailInfo}>
          <div>
            <span>
              <MailIcon style={{ marginRight: "0.5rem" }} />
            </span>
            <span
              style={{
                textAlign: "left",
                marginRight: "0.5rem"
              }}
              ref={emailRef}
            >
              {email}
            </span>
          </div>
          <span data-tooltip-id="copy-icon-id">
            {" "}
            <CopyIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleCopy(email);
              }}
            />
          </span>
        </div>
        <div>
          <span>
            <PhoneIcon style={{ marginRight: "0.5rem" }} />
          </span>
          <span style={{ textAlign: "left" }}>
            {phone?.length ? phone : "---"}
          </span>
        </div>
      </div>
      <Tooltip
        id={"copy-icon-id"}
        positionStrategy="fixed"
        place="top"
        className={`${styles.copyTooltip} increase-z-index`}
        offset={5}
      >
        <div>{tooltipMessage}</div>
      </Tooltip>
    </div>
  );
};

export { ProfileCard };
