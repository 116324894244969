export const staticStrings = {
  account360: {
    title: "Account 360",
    accountdropdown: {
      Search: "Search",
      noAccountExist: "No Accounts Exist"
    },
    incortaLabel: "Incorta Customer 360",
    linkedInLabel: "Linkedin",
    marketingActivitiesLabel: "Marketing Activities",
    activitytrends: {
      title: "Activity Trends",
      range2Years: "2 years",
      range1Year: "1 year",
      range90Days: "90 days",
      range60Days: "60 days",
      range30Days: "30 days",
      outbound: "Outbound",
      inbound: "Inbound",
      meetings: "Meetings",
      emails: "Emails",
      dataFeedback: "Data Feedback",
      reqestEmailID: "Request Partner Email",
      domainFeedback: "Request Domain",
      divisionInfoText: "Displays Current Team Member's Divisions"
    },
    daysdropdown: {
      showData: "Showing data for "
    },
    detailspage: {
      details: "Details",
      search: "Search by keyword or person name",
      emailtab: {
        from: "From:",
        to: "To:"
      },
      emails: "Emails",
      meetings: "Meetings",
      noEmails: "No Emails Found",
      noMeetings: "No Meetings Found",
      recepients: "Recipients",
      more: "more",
      plus: "+",
      upcomingMeetings: "Upcoming Meetings",
      pastMeetings: "Past Meetings"
    },
    meetingcard: {
      attendess: "Attendees",
      accepted: "accepted",
      declined: "declined",
      tentative: "tentative"
    },
    popup: {
      status: ["Accepted", "Declined", "Tentative", "Not Responded"],
      emailStatus: ["To", "CC"],
      attendees: "Attendees",
      organiser: "Organiser"
    },
    upcomingmeeting: {
      title: "Upcoming Meetings",
      noMeetings: "No Upcoming Meetings",
      meetingsInfoMsg:
        "Meetings scheduled for the <br /> next 30 days are displayed."
    },
    tabs: {
      summary: "Summary",
      engagedPeople: "Engaged People",
      accountTeam: "Account Team",
      internal: "Internal",
      external: "External",
      engagementScore: "Engagement Score",
      domains: "Domains"
    },
    engagedPeople: {
      viewingData: "Viewing data for",
      filterData: "Filter By : ",
      allPeople: "All People",
      onePerson: "Person",
      manyPeople: "People",
      search: "Search",
      owner: "Owner",
      internal: "Internal",
      external: "External",
      done: "Done",
      noRecordsInternal:
        "No users found for applied filters.Please reset the filters or check the external tab",
      noRecordsExternal:
        "No users found for applied filters.Please reset the filters or check the internal tab",
      clear: "Clear",
      lastEngagedTooltip:
        "Last time an email was sent or <br /> a meeting was attended with <br /> a customer contact.",
      include: "INCLUDE",
      exclude: "EXCLUDE",
      noData: "No engaged contacts found for this account",
      selectedPeople: "Selected People",
      emailHeaderTooltipMessage: "Each email is measured at",
      meetingsHeaderTooltipMessage:
        "Each meeting is measured by the amount of minutes the meeting was scheduled for"
    },
    externalUserActivitySideBar: {
      activities: "- Activities",
      summary: "Summary",
      title: "Title",
      email: "Email: ",
      phone: "Phone: ",
      connections: "Connections",
      inboundMails: "Inbound Mails",
      outboundMails: "Outbound Mails"
    },
    connectionsGraph: {
      infoTextExternal: " external people with maximum activity time.",
      infoTextInternal: " internal people with maximum activity time.",
      showData: "Showing data for top ",
      graphView: "Graph View",
      tableView: "Table View",
      internal: "internal",
      external: "external",
      noData: "No data available",
      totalActivity: "Total Activity"
    },
    engagementScore: {
      scoreTrendTitle: "Score Trend",
      accountInsightsTitle: "Account Insights",
      engagementScore: "Engagement Score",
      high: "Highest",
      low: "Lowest",
      cur: "CURRENT",
      noScoreAvailable:
        "No score available. Score will be calculated from next month.",
      engagmentScoreTooltip:
        "Engagement Score is measured every 30 days and is a composite of <br />Engaged Contacts (23%), Past Meetings (25%), Future Meetings (35%) ,<br /> Email Sent (2%) and Received (5%) and their ratios (10%).",
      divisionDropDownPlaceholder: "All Divisions",
      divisionDropDownAll: "All"
    },
    accountTeam: {
      buttonStyle: {
        meetTeam: { marginRight: "0.5rem", marginBottom: "0.3rem" },
        emailTeam: { marginRight: "0.5rem" }
      },
      emailTeam: "Email Team",
      meetTeam: "Meet Team"
    }
  },
  filterIngestion: {
    title: "Filter Ingestion",
    searchPlaceHolder: "Search by field or pattern",
    filterInfoText:
      "The entirety of any email or meeting that meets the criteria of this filter list will be blocked from being ingested and visualized",
    createButton: "Add Filter",
    all: "All",
    any: "Any",
    confirmationTitleFilterDelete: "Delete Filter?",
    confirmationBodyFilterDelete: "This action cannot be undone.",
    deleteSuccess: "Filter deleted successfully.",
    delete: "Delete",
    cancel: "Cancel",
    addFilter: "Add Local Filter",
    editFilter: "Edit Filter",
    save: "Save",
    add: "Add",
    noData: "No Data",
    addFilterSuccess: "Filter added successfully. It will reflect in 48 hours.",
    editFilterSuccess: "Filter updated successfully",
    field: "Field",
    type: "Type",
    warning: "Warning:",
    warningInfoText1: " Remember to handle",
    warningInfoText2: " from/organizer ",
    warningInfoText3:
      "email/meeting filters with the same value, to avoid display errors",
    contacts: "CONTACTS"
  },
  reporting: {
    title: "Reporting",
    accountTab: "Accounts",
    selectDivisions: "Select Divisions",
    division: "Division",
    divisions: "Divisions",
    selectFunctions: "Select Functions",
    function: "Function",
    functions: "Functions",
    peopleTab: "People",
    range2Years: "2 years",
    range1Year: "1 year",
    range90Days: "90 days",
    range60Days: "60 days",
    range30Days: "30 days",
    submitLabel: "Submit",
    searchPlaceHolder: "Search",
    all: "All",
    accountLabel: "Account",
    accountsLabel: "Accounts",
    selectAccounts: "Select Accounts",
    allAccounts: "All Accounts",
    accounts: {
      treeDropdownlabel: "Filter By :",
      memberColumnTooltip: "Includes past and present members"
    },
    people: {
      searchPlaceholder: "Search by name"
    },
    customTeam: {
      teamDetails: "Details",
      team: "Team",
      teams: "Teams",
      selectTeams: "Select Teams",
      searchByMemberName: "Search by member name",
      members: "Members",
      member: "Member"
    },
    myActivities: {
      noDataInfo: "Your data is not getting ingested for any account"
    },
    engagementScoreInfo:
      "Engagement Score is measured every 30 days and is a composite of <br />Engaged Contacts (23%), Past Meetings (25%), Future Meetings (35%) ,<br /> Email Sent (2%) and Received (5%) and their ratios (10%)."
  },
  userManagement: {
    title: "User Management",
    tabs: ["Users", "Custom Teams"],
    cards: ["Total", "Error", "Active", "Inactive", "No Ingestion"],
    location: "Location",
    locations: "Locations",
    selectLocations: "Select Locations",
    searchPlaceholder: "Search Name or Email",
    usersLabel: "Users",
    userLabel: "User",
    selectUsers: "Select Users",
    addUser: "Add User",
    addUsersLabel: "Add Users",
    removeUsersLabel: "Remove Users",
    customTeamSearchPlaceholder: "Search by name, function or division",
    customTeamDetailsSearchPlaceholder: "Search by name",
    addUserClassBtn: "addUserBtn",
    addUsers: "Add Users",
    removeUsersValidationMsg: "Select atleast one member to remove.",
    addUsersValidationMsg: "Select atleast one member.",
    removeUsersAtLeastOneMemberValidationMsg:
      "Atleast one member is required in the team.",
    memberSelected: "member selected",
    membersSelected: "members selected",
    editUser: "Edit User",
    deletePopUpDescription:
      "The user's information will no longer be ingested.",
    suspend: "Suspend ingestion?",
    resume: "Resume ingestion?",
    resumePopUpDescription: "The user's information will be ingested.",
    userStatusMessage: ["Active", "Inactive", "Error"],
    userStatusResponse: ["ACTIVE", "SUSPENDED", "ERROR"],
    noVisibilityLevelSelected: "No visibility level selected",
    userAdded: "User Added!",
    userSave: "User Saved!",
    userIngestionSuspend: "Ingestion Suspended!",
    userIngestionResume: "Ingestion Resumed!",
    submit: "Submit",
    cancel: "Cancel",
    addButtonLabel: "Add",
    next: "Next",
    save: "Save",
    visibilityLevel: "Visibility Level",
    userStepperHeaderList: ["General Details", "User Acess"],
    userDoesNotExist: "User does not exist",
    emailRequired: "Email is required",
    enterValidEmail: "Enter a valid email address",
    createCustomTeam: "Create Custom Team",
    somethingWentWrong: "Something went wrong",
    actionsOptionWithSuspendDisable: [
      "Suspend Ingestion",
      "Disable portal access",
      "Edit"
    ],
    teamDetails: "Team Details",
    create: "Create",
    generalInformation: "General Information",
    teamName: "Team Name",
    selectMembers: "Select Members",
    members: "Members",
    selectSource: "Select Source",
    showUsers: "Show Users",
    enterName: "Enter name",
    actionsOptionWithResumeDisable: [
      "Resume Ingestion",
      "Disable portal access",
      "Edit"
    ],
    actionsOptionWithSuspendResume: [
      "Suspend Ingestion",
      "Enable portal access",
      "Edit"
    ],
    actionsOptionWithResumeResume: [
      "Resume Ingestion",
      "Enable portal access",
      "Edit"
    ],

    select: "Select",
    enabled: "Enabled",
    disabled: "Disabled",
    confirm: "Confirm",
    disablePortalAccess: "Disable portal access?",
    disablePopUpDescrition:
      "The user will no longer be able to access the portal or any information",
    userPortalAccessSuspend: "Portal Access Suspended!",
    enablePortalAccess: "Enable portal access?",
    enablePopUpDescrition:
      "This will grant the user permission to access the portal.",
    userPortalAccessEnabled: "Portal Access Resumed!",
    userAccess: ["true", "false"],
    userPermission: "Permission",
    unassigned: "Unassigned",
    teamVisibilityPopupTitle: "Account Team Visibility",
    teamNameValidation: "Team Name required",
    selectUsersValidation: "Select atleast one member",
    searchPlaceHolder: "Search by team name",
    teamDetailsBtn: "Team Details",
    customTeamTitle: "Custom Teams",
    emailPlaceholder: "Enter Broadcom Email id",
    enterValidDomain: "Enter valid domain name (broadcom.com).",
    deleteTeam: "Delete Team ?",
    deleteTeamSuccess: "Team deleted successfully.",
    noOfLoginsTooltip: "No. of Logins in last 90 days",
    ingestionStatus: {
      active: "active",
      inactive: "inactive",
      error: "error",
      total: "",
      "no ingestion": "no_ingestion"
    },
    acccounts: {
      reingest: "Reingest",
      reingestionSuccessMessage:
        "Re-ingestion scheduled. It will reflect in 48 hours.",
      searchPlaceholder: "Search by Account Name",
      accounts: "Accounts",
      accountStatusResponse: {
        Synced: "Synced",
        Pending: "Pending",
        "In Progress": "In Progress",
        Inactive: "Inactive"
      },
      accountStatusColor: {
        Synced: { background: "#f1f8e8", color: "#74b816" },
        Pending: { background: "#F1F3F5", color: "#97989B" },
        "In Progress": { background: "#FDF6E5", color: "#E9A100" },
        Inactive: { background: "#F1F3F5", color: "#97989B" }
      },
      reingestConfirmMessage: "Re-ingestion confirmation",
      reingestConfirmDescription:
        "This action will re-ingest data. Are you sure you want to continue?"
    },
    clearFilter: "Clear Filters"
  },
  ingestion: {
    ingestionTitle: "Ingestion",
    tabs: ["Filters", "Domains", "Accounts"],
    domains: {
      searchPlaceHolder: "Search by domain",
      accountsCard: "Accounts",
      domainsCard: "Domains",
      emailsCard: "Partner Emails",
      accountLabel: "Account",
      accountsLabel: "Accounts",
      allAccounts: "All Accounts",
      viewDetailsAction: "View Details",
      domainDetails: {
        domainsCard: "Domains",
        emailsCard: "Emails",
        domains: "domains",
        emails: "emails",
        deleteModalTitle: "Delete Domain ",
        deleteEmailModalTitle: "Delete Partner Email ",
        confirmationMessageBodyDomainDelete:
          "Deleting this Domain will remove all its data. Click Delete to confirm.",
        confirmationMessageBodyEmailDelete:
          "Deleting this Partner Email will remove all its data. Click Delete to confirm.",
        domainStatusResponse: {
          SYNCED: "Synced",
          PENDING: "Pending",
          "In Progress": "In Progress"
        },
        domainStatusColor: {
          SYNCED: { background: "#f1f8e8", color: "#74b816" },
          PENDING: { background: "#F1F3F5", color: "#97989B" },
          "In Progress": { background: "#FDF6E5", color: "#E9A100" }
        },

        domainReingest: "Ingest",
        searchPlaceholder: "Search by Domain Name",
        emailSearchPlaceholder: "Search by Partner Email",
        addDomain: "Add Domain",
        addEmailId: "Add Partner Email",
        addDomainPlaceHolder:
          "Enter comma separated values for multiple domain addition",
        addEmailIdPlaceholder:
          "Enter comma separated values for multiple Partner Email addition",
        addDomainModalInputTitle: "Domain Name",
        addEmailModalInputTitle: "Partner Email",
        reingestionSuccessMessage:
          "Ingestion scheduled. It will reflect in 48 hours.",
        addDomainSuccessMessage: "Domains added successfully!",
        addEmailSuccessMessage: "Partner Emails added successfully!",
        deleteDomainSuccessMessage:
          "Deletion scheduled. Data deletion will reflect in 48 hours."
      },
      active: "active"
    },
    accounts: {
      searchPlaceholder: "Search by Account",
      cards: ["Total", "Active", "Inactive"],
      classificationBadgeColor: {
        strategic: { background: "#F1EDFD", color: "#7048E8" },
        commercial: { background: "#E7F2F4", color: "#0D8091" },
        corporate: { background: "#FEF0E6", color: "#F76707" }
      },
      genericBadgeColor: { background: "#e8f0fc", color: "#2670f0" },
      active: "true",
      inactive: "false",
      actions: {
        activate: "Activate",
        inactivate: "Inactivate",
        updateLinkedIn: "Update Linkedin"
      },
      modal: {
        actions: { confirm: "Confirm", cancel: "Cancel" },
        description: {
          activate:
            "will be marked as active and data will start getting ingested.",
          inactivate: "will be marked as inactive and ingestion will be paused."
        }
      },
      linkedModalHeader: "Linkedin URL",
      unknown: "Unknown",
      linkedInUrl: " https://www.linkedin.com/company/"
    }
  },
  integration: {
    title: "Integration",
    tabs: ["Eloqua"],
    eloqua: {
      eloquaStatusResponse: {
        true: "Active",
        false: "Inactive"
      },
      ACCEPTED: "ACCEPTED",
      eloquaStatusColor: {
        true: { background: "#f1f8e8", color: "#74b816" },
        false: { background: "#F1F3F5", color: "#97989B" }
      },
      searchPlaceholder: "Search by Rule Name ",
      searchPlaceholderSignature: "Search by Email or Name",
      rulesCard: "Rules",
      signaturesCard: "Signatures",
      signatureInfoText:
        "Below are the signatures that pass the rules you’ve added to push into Eloqua. ",
      updateSignature: "Update Signatures",
      deleteSignature: "Delete Signature",
      pushMessage: (selectedSignature: string, signature: string) =>
        `Are you sure you want to push ${selectedSignature} selected ${signature} to Eloqua?`,
      deleteMessage: (selectedSignature: string, signature: string) =>
        `Are you sure you want to delete ${selectedSignature} selected ${signature}?`,
      deleteInfoText: "This action will remove the signature from the database",
      updateEloqua: "Update Eloqua",
      signatureUpdateInfoText: (time: string) =>
        `This data was last refreshed at ${time} UTC`,
      signatureStatusResponse: {
        true: "New",
        false: "Existing"
      },
      signatureStatusColor: {
        true: { background: "#f1f8e8", color: "#74b816" },
        false: { background: "#F1F3F5", color: "#97989B" }
      },
      signatureDeleteSuccessMessage: (signature: string) =>
        `${signature} deleted successfully!`,
      signatureUpdateSuccessMessage: (signature: string) =>
        `${signature} accepted successfully and will be pushed to Eloqua in 24hrs`,
      addRuleButton: "Add Rule",
      name: "Name",
      enter: "Enter",
      addRuleHeader: "Add Rule",
      ruleDetails: "Rule Details",
      criteria: "Criteria",
      condition: "Condition",
      value: "Value",
      ruleCreatedSuccessMessage: "Rule created successfully",
      ruleUpdatedSuccessMessage: "Rule updated successfully",
      allAccounts: "All Accounts",
      actionsOptionWithActivate: ["Activate", "Edit", "Delete"],
      actionsOptionWithInActivate: ["Inactivate", "Edit", "Delete"],
      add: "Add",
      deleteRuleSuccessMessage: "Rule deleted successfully",
      inactivateSuccessMessage: "Rule inactivated successfully",
      activateSuccessMessage: "Rule Activated successfully",
      edit: "Edit",
      inactivate: "Inactivate",
      activate: "Activate",
      delete: "Delete",
      update: "Update",
      editRule: "Edit Rule",
      activateModalDescription:
        "This rule will be considered for pushing data to Eloqua",
      inactivateModalDescription:
        "This rule will be no longer considered for pushing data to Eloqua",
      delelteModalDescription:
        "Deleting this Rule will remove all its data. Click Delete to confirm.",
      accounts: "Accounts",
      setRule: "Set Rule",
      accountLabel: "account",
      accountsLabel: "accounts",
      accountsExclude: "Accounts Excluded",
      deleteRuleMessage: "Delete Rule?"
    }
  },
  dateFormats: {
    UTC: "MM/DD/YYYYTHH:mm:ssZ",
    YYYYMMDD_WITH_HOURS: "YYYY-MM-DDTHH:mm:ss",
    YYYYMMDD_WITH_HOURS_TZ: "YYYY-MM-DDTHH:mm:ssZ",
    YYYYMMDD: "YYYY-MM-DD",
    DMMMYY: "D MMM 'YY",
    HMMA: "h:mm a",
    MMMDD: "MMM DD",
    MMMYY: "MMM YY",
    HMMAZ: "h:mm a z",
    MMDDYYYY_COMMON: "MM/DD/YYYY",
    stdDate: "dddd DD MMMM, YYYY",
    HMMAMMDDYY: "h:mm a, DD/MM/YY",
    DDMMYY: "DD/MM/YY",
    DEFAULT_UI_DATE_FORMAT: "MMM DD, YYYY",
    DEFAULT_UI_DATE_FORMAT_1: "DD MMMM YYYY",
    INPUT_UI_DATE_FORMAT: "DD-MMM-YYYY",
    DEFAULT_UI_DATE_TIME_FORMAT: "h:mm a, MMM DD, YYYY",
    DEFAULT_UI_DATE_TIME_FORMAT_1: "h:mm a, DD MMMM  YYYY",
    MMMYYY: "MMM YYYY",
    MMM: "MMM"
  },
  headers: {
    title: "Sales Activities"
  },
  hierarchyTreeDropdown: {
    clear: "Clear",
    done: "Done"
  },
  auth_error_screens: {
    unauthorized_access:
      "Oops,looks like you don't have authorization to access this page."
  },
  notification_messages: {
    something_wrong: "Something went wrong, please try again!",
    error_while_copy: "Failed to copy email"
  },
  selected: "selected",
  dropdownAllabel: "All",
  noValue: "---",
  fetchDataMsg: "We are fetching the details. Please wait... ",
  commonTexts: {
    noRecordsFound: "No records found",
    contactsEngagedTooltip:
      "Number of customer contacts <br /> engaged by sending an email <br /> or attending a meeting.",
    lastEngagedTooltip:
      "Last time an email was received <br /> or a meeting was attended",
    search: "Search",
    select: "Select",
    actionsLabel: "Actions",
    checkboxLabel: "checkbox",
    notApplicable: "NA",
    copyText: "Copied to clipboard",
    yes: "Yes",
    close: "Close",
    no: "No",
    update: "Update"
  },
  commonValidationTexts: {
    intText: "Value must be integer",
    valueRequired: "Value is required",
    nameRequired: "Name is required",
    nameCharacterLimit: "Name must be less than 128 characters"
  },
  AllLabelUppercase: "ALL",
  dropdownCheckbox: {
    sortKeys: {
      id: "id",
      value: "value",
      name: "name"
    },
    filterByLabel: "Filter By :"
  },
  profileCard: {
    linkedIn: "LinkedIn",
    addContact: "Add Contact",
    contactAdded: "Contact Added",
    copyEmail: "Copy Email",
    copied: "Copied",
    broadcom: "Broadcom"
  },
  linkedInFeedBackModal: {
    feedbackTitle: "Feedback",
    linkNavigationQuestion: "Did the link take you to the intended profile?",
    thanksText: "Thank you for your feedback!",
    inputFeedbackText: "Your input helps us to improve our services.",
    failLinkText: "We are sorry to hear that.",
    investigateText: "We’ll investigate the issue.",
    feedbackText: "Thank you for your valuable feedback.",
    feedbackStatus: {
      reject: "REJECTED",
      accept: "ACCEPTED"
    },
    refreshPage: {
      engagePeople: "engagedPeople",
      externalConnections: "externalConnections",
      accountTeams: "accountTeams"
    }
  }
};
