import { useEffect, useState } from "react";
import ClayNav from "@clayui/nav";
import { ROUTE_PATHS, routesConfig } from "@constants";
import { IRoute } from "@interfaces";
import { Link, useLocation } from "react-router-dom";
import styles from "./SideNav.module.scss";
import ClosedMenu from "@images/close-menu.svg";
import OpenMenu from "@images/open-menu.svg";
import PermissionChecker from "../../common-ui-modules/common-components/PermissionChecker/PermissionChecker";
import { setActiveTab } from "@store/userManagement/userManagement.slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/configureStore";
import { setSelectedDivision } from "@store/account360/account360.slice";

function SideNav() {
  const location = useLocation();
  const { pathname } = location;
  const [toggler, setToggler] = useState(true);
  const { selectedAccount, accountId } = useSelector(
    (state: RootState) => state.account360
  );
  const dispatch = useDispatch();

  let sideNavOptions = [...routesConfig];
  sideNavOptions = sideNavOptions
    .filter((option: IRoute) => !!option.sideNav)
    .sort((a: any, b: any) => a.sideNavOrder - b.sideNavOrder);

  const handleToggle = () => {
    setToggler(!toggler);
  };

  const isActivePath = (path: string) => {
    if (pathname === "/") return ROUTE_PATHS.ACCOUNT_360.includes(path);
    return path !== "/" ? pathname.includes(path) : path === pathname;
  };

  const getNavPath = (path: string) => {
    if (accountId && path === ROUTE_PATHS.ACCOUNT_360) {
      return `${path}?party-id=${selectedAccount?.party_id}`;
    }
    return path;
  };

  useEffect(() => {
    setToggler(true);
    window.scrollTo({ top: 0 });
    dispatch(setSelectedDivision(""));
  }, [pathname]);

  return (
    <div className={`${styles.navArea}  ${toggler ? styles.toggled : ""}`}>
      <ClayNav stacked={true} className={`${styles.sidebarNav}`}>
        <ClayNav.Item className={styles.sidebarBrand}>
          <div
            className={`${styles.navLink} ${styles.navText}`}
            onClick={() => handleToggle()}
            id="nav_hamburger"
          >
            <img
              className={styles.navIcon}
              src={toggler ? ClosedMenu : OpenMenu}
              alt="menu-icon"
            />
          </div>
        </ClayNav.Item>
        {sideNavOptions.map((nav, index) => {
          return (
            <PermissionChecker
              key={nav.id}
              omitChecking={!nav.permissionRequired}
              requiredPermission={nav.userPermission}
            >
              <ClayNav.Item>
                <Link
                  className={`${styles.navLink} ${styles.navText} ${
                    isActivePath(nav?.path) ? styles.active : ""
                  }`}
                  to={getNavPath(nav.path)}
                  onClick={() => {
                    if (nav.path === ROUTE_PATHS.USER_MANAGEMENT) {
                      dispatch(setActiveTab(0));
                    }
                  }}
                  rel="noopener noreferrer"
                  id={`menu_item_${index}`}
                >
                  <div className={`${styles.iconInfo}`}>
                    <div className={`${styles.icon}`}>
                      {
                        <img
                          className={styles.navIcon}
                          src={nav.icon}
                          alt="home"
                        />
                      }
                    </div>
                    {!toggler && (
                      <div className={`${styles.name}`}>{nav.name}</div>
                    )}
                  </div>
                </Link>
              </ClayNav.Item>
            </PermissionChecker>
          );
        })}
      </ClayNav>
    </div>
  );
}

export { SideNav };
