export const user_permissions = {
  account360: {
    VIEW_ACCOUNT_360: "view_account_360"
  },
  reporting: {
    VIEW_REPORTING: "view_reporting",
    VIEW_ACCOUNTS: "view_reporting_accounts",
    VIEW_PEOPLE: "view_reporting_people",
    VIEW_CUSTOM_TEAMS: "view_reporting_custom_teams",
    VIEW_MY_ACTIVITIES: "view_reporting_my_activity"
  },
  userManagement: {
    VIEW_USERS: "view_users",
    MANAGE_USERS: "manage_users",
    MANAGE_CUSTOM_TEAM: "manage_user_management_custom_team"
  },
  customFilters: {
    VIEW_CUSTOM_FILTERS: "view_custom_filters",
    MANAGE_CUSTOM_FILTERS: "manage_custom_filters"
  },
  ingestion: {
    VIEW_DOMAINS: "view_accountdomainlookup",
    EDIT_DOMAINS: "edit_accountdomainlookup",
    VIEW_ACCOUNTS: "view_ingestion_accounts"
  },
  restrictedUser: {
    RESTRICTED_ACCESS: "restricted_access"
  },
  integration: {
    VIEW_ELOQUA_FILTER: "view_eloquafilter",
    ELOQUA: {
      SIGNATURES: "view_signature_scraping_users"
    }
  }
};
