import SummaryBox from "@components/SummaryBox/SummaryBox";
import SearchInput from "@components/SearchInput/SearchInput";
import styles from "./Domains.module.scss";
import { useCallback, useEffect, useState } from "react";
import { staticStrings } from "@staticStrings";
import { debounce } from "@utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configureStore";
import { staticIds } from "@staticIds";
import { IDomainsExportData } from "interfaces/domains.interface";

import { setSelectedPage } from "@store/filterIngestion/domains/domains.slice";
import { PATHS } from "@constants";
import { axiosHttp } from "@common-services";
import AccountsImage from "@images/userManagement/UserManagementTotal.svg";
import DomainsImage from "@images/DomainsIcon.svg";
import MailsImage from "@images/MailsIcon.svg";
import CustomButton from "../../common-ui-modules/common-components/CustomButton/CustomButton";
import DownloadIcon from "../../assets/icons/Download.svg";
import moment from "moment";
import { TreeViewDropdown } from "../../common-ui-modules/common-components/dropdown-tree-view/TreeViewDropdown";
import {
  setHeirarchyTreeResponseLoader,
  setHierarchyTreeResponse
} from "@store/reporting/account/accountReport.slice";
import {
  convertDataToFilter,
  convertDataToTree,
  HelperFunctions
} from "../../utils/HelperFunctions";
import { ALL_OPTIONS } from "../../utils/constants/defaultConstants";
import { IFiltersState, IHierarchyTreeData, ITreeNode } from "@interfaces";

interface DomainsHeaderProps {
  setSearchKeyWord: (keyWord: string) => void;
  setPayload: (state: IFiltersState[]) => void;
}

const DomainsHeader: React.FC<DomainsHeaderProps> = ({
  setSearchKeyWord,
  setPayload
}) => {
  const [disableExportButton, setDisableExportButton] =
    useState<boolean>(false);
  const { all, allAccounts, accountLabel, accountsLabel, searchPlaceHolder } =
    staticStrings.reporting;

  const [prevSelection, setPrevSelection] = useState<IHierarchyTreeData[]>([]);
  const [label, setLabel] = useState(accountsLabel);

  const [searchInput, setSearchInput] = useState<string>("");
  const dispatch = useDispatch();
  const { domainsCardContent, searchKeyWord } = useSelector(
    (state: RootState) => state.domains
  );

  useEffect(() => {
    setSearchInput(searchKeyWord);
  }, [searchKeyWord]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchKeyWord = useCallback(
    debounce(setSearchKeyWord, 500),
    [setSearchKeyWord]
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
    debouncedSetSearchKeyWord(value);
  };

  const fetchPageData = useCallback(
    async (pageNo: number, pageSize: number) => {
      const endpoints = PATHS.INGESTION.DOMAINS.EXPORT;
      const apiUrl = `${endpoints}?pageNo=${pageNo}&pageSize=${pageSize}`;

      try {
        const response = await axiosHttp.get(apiUrl);
        return response.data;
      } catch (error) {
        if (error instanceof Error) {
          console.error("Error:", error.message);
        } else {
          console.error("Unexpected error:", error);
        }
        throw error;
      }
    },
    []
  );

  const fetchAllData = async () => {
    const pageSize = 5000;
    let currentPage = 1;
    let accumulatedData: IDomainsExportData[] = [];
    let totalPages = 1;
    try {
      do {
        const data = await fetchPageData(currentPage, pageSize);
        accumulatedData = [...accumulatedData, ...data.response.data];
        totalPages = Math.ceil(data.response.total / pageSize);
        currentPage++;
      } while (currentPage <= totalPages);
      return accumulatedData;
    } catch (error) {
      console.error("Failed to fetch all data:", error);
      return [];
    }
  };

  const transformDataForCSV = (data: IDomainsExportData[]) => {
    const transformedData = data.map((item) => ({
      account_name: item.account_name,
      party_id: item.party_id,
      domain_name: item.domain_name,
      sales_area: item.sales_area,
      sales_org: item.sales_org,
      sales_region: item.sales_region,
      total_emails: item.total_emails,
      total_meetings: item.total_meetings,
      type: item.type
    }));
    return transformedData;
  };

  const escapeCsvValue = (value: any) => {
    value = String(value);
    if (value.includes('"')) {
      value = value.replace(/"/g, '""');
    }
    return `"${value}"`;
  };

  const generateCSVContent = (data: IDomainsExportData[]) => {
    const headers = [
      "Account Name",
      "Party Id",
      "Sales Area",
      "Sales Org",
      "Sales Region",
      "Domain",
      "Total Emails",
      "Total Meetings",
      "Type"
    ];
    const csvRows = [
      headers.join(","), // Add headers row
      ...data.map((row) =>
        [
          escapeCsvValue(row.account_name),
          escapeCsvValue(row.party_id),
          escapeCsvValue(row.sales_area),
          escapeCsvValue(row.sales_org),
          escapeCsvValue(row.sales_region),
          escapeCsvValue(row.domain_name),
          escapeCsvValue(row.total_emails),
          escapeCsvValue(row.total_meetings),
          escapeCsvValue(row.type)
        ].join(",")
      )
    ];
    return csvRows.join("\n");
  };

  const downloadCSV = (csvContent: string, filename: string) => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExport = async () => {
    setDisableExportButton(true);
    const data = await fetchAllData();
    const transformedData = transformDataForCSV(data);
    const csvContent = generateCSVContent(transformedData);
    const timestamp = moment()
      .utc()
      .format(staticStrings.dateFormats.DEFAULT_UI_DATE_FORMAT);
    const filename = `Sales_activities_domains_${timestamp}.csv`;
    downloadCSV(csvContent, filename);
    setDisableExportButton(false);
  };

  const handleCheckBoxSelection = (data: ITreeNode[], count: number) => {
    const payload =
      data.length === 0 || (data.length === 1 && data[0].value === all)
        ? []
        : convertDataToFilter(data);
    if (data.length === 0) setLabel(accountsLabel);
    else if (data[0] && data[0].value === all) {
      setLabel(allAccounts);
    } else if (count === 1) {
      setLabel(`${count} ${accountLabel}`);
    } else {
      setLabel(`${count} ${accountsLabel}`);
    }
    dispatch(setSelectedPage(1));
    setPayload(payload);
  };

  useEffect(() => {
    dispatch(setHeirarchyTreeResponseLoader(true));
    axiosHttp
      .post(`${PATHS.REPORTING.HIERARCHY_TREE_LIST}`, {
        include_inactive_accounts: true,
        include_accounts_valid_for_ingestion_domain: true
      })
      .then((response) => {
        const result = response.data;
        const treeData = convertDataToTree(result.response.data, []);
        result.response.data = HelperFunctions.sortTreeData([
          { ...ALL_OPTIONS, children: treeData }
        ]);
        dispatch(setHierarchyTreeResponse(result));
        dispatch(setHeirarchyTreeResponseLoader(false));
      })
      .catch((error) => {
        console.error("Error:", error);
        dispatch(setHeirarchyTreeResponseLoader(false));
      });
  }, [dispatch]);
  return (
    <div className={styles.domainsCardAndSearchSection}>
      <div className={styles.domainsCardOuterContainer}>
        {
          <div className={`${styles.domainsCard} `} key={AccountsImage}>
            <div className={styles.domainsCardExtraPadding}>
              <SummaryBox
                headerText={domainsCardContent[0]?.headerText ?? "---"}
                bodyText={staticStrings.ingestion.domains.accountsCard}
                image={AccountsImage}
                summaryCardId={`${staticIds.ingestion.domains.accountsCard}0`}
              />
            </div>
          </div>
        }
        {
          <div className={`${styles.domainsCard} `} key={DomainsImage}>
            <div className={styles.domainsCardExtraPadding}>
              <SummaryBox
                headerText={domainsCardContent[1]?.headerText ?? "---"}
                bodyText={staticStrings.ingestion.domains.domainsCard}
                image={DomainsImage}
                summaryCardId={`${staticIds.ingestion.domains.accountsCard}1`}
              />
            </div>
          </div>
        }
        {
          <div className={`${styles.domainsCard} `} key={MailsImage}>
            <div className={styles.domainsCardExtraPadding}>
              <SummaryBox
                headerText={domainsCardContent[2]?.headerText ?? "---"}
                bodyText={staticStrings.ingestion.domains.emailsCard}
                image={MailsImage}
                summaryCardId={`${staticIds.ingestion.domains.accountsCard}2`}
              />
            </div>
          </div>
        }
      </div>

      <div className={styles.domainsSearchAndAddSection}>
        <div className={styles.domainsAccountsSection}>
          <div className={styles.textLabel}>
            {staticStrings.reporting.accounts.treeDropdownlabel}
          </div>
          <div className={styles.treeDropdown}>
            <TreeViewDropdown
              showDropdown="always"
              texts={{
                inlineSearchPlaceholder: searchPlaceHolder,
                noMatches: staticStrings.commonTexts.noRecordsFound
              }}
              dropdownLabel={label}
              handleCheckBoxSelection={handleCheckBoxSelection}
              page="reporting"
              submitButtonMessage={staticStrings.hierarchyTreeDropdown.done}
              prevSelection={prevSelection}
              setPrevSelection={setPrevSelection}
              dropdownId="account_filter_dropdown"
            />
          </div>
        </div>
        <div className={styles.searchdomainsContainer}>
          <SearchInput
            onChange={handleSearch}
            searchValue={searchInput}
            placeholder={staticStrings.ingestion.domains.searchPlaceHolder}
          />
        </div>
        <div>
          <CustomButton
            id="data-feedback-btn"
            onClick={handleExport}
            displayType={"secondary"}
            disabled={disableExportButton}
          >
            <img
              src={DownloadIcon}
              className={styles.svgIcon}
              alt="download-icon"
            />
            Export
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default DomainsHeader;
